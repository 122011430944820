import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

class NotFoundPage extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>Not Found | ZivugTech</title>
        </Helmet>
        <div className="main mui-container-fluid">
          <div className="content mui-row">
            <div className="not-found-left">
              <p>404</p>
            </div>
            <div className="not-found-right">
              <div className="not-found">
                <p className="oops">Oops</p>
                <p className="page-not-found">Page not found</p>
              </div>
              <p className="not-found-reasons">
                The page you are looking for was moved, removed, renamed, or
                might have never existed.
              </p>
              <button className="btn-flat btn-primaryAccent">
                <Link to={`/`}>Go back to home</Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NotFoundPage;

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as modalActions from "../../../actions/modalActions";
import * as editResumeActions from "../../../actions/editResumeActions";
import _ from "lodash";
import SweetAlert from "react-bootstrap-sweetalert";
import ResumeForm from "./resumeForm";

const emptyResume = {
  types: [],
  images: [],
  references: [{ contactInfo: [{}] }, { contactInfo: [{}] }],
  shuls: [{}],
  education: [{}, {}, {}],
  occupation: {},
  camps: [{}],
  volunteer: [{}],
  familyInfo: {},
  languages: [],
  displayPersonalContactInfo: true,
};

class CreateResumeNew extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      isMine: props.location.pathname === "/myResume",
      resume: emptyResume,
      errors: {},
      edited: false,
      saved: false,
    };
    this.saveResume = this.saveResume.bind(this);
    this.deleteExtraEmptyObjects = this.deleteExtraEmptyObjects.bind(this);
  }

  componentDidMount() {
    this.loadResume(this.props);

    this.unblockRouter = this.context.router.history.block((next) => {
      if (this.state.edited && !this.state.confirmedLeave) {
        this.setState({
          alert: (
            <SweetAlert
              warning
              showCancel
              cancelBtnText="Stay on page"
              confirmBtnText="Leave Anyway"
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="default"
              title="Are you sure?"
              onConfirm={() => {
                this.setState({ confirmedLeave: true, alert: null }, () => {
                  this.context.router.history.push(next.pathname);
                });
              }}
              onCancel={() => this.setState({ alert: null })}
            >
              You have unsaved changes.
            </SweetAlert>
          ),
        });
        return false;
      }
    });
  }

  componentWillUnmount() {
    this.unblockRouter();
  }

  loadResume(props) {
    if (props.match.params.id) {
      //editing a resume
      this.props.editResumeActions.loadResume(props.match.params.id);
    } else if (
      props.location.pathname.includes("myResume") &&
      props.myResumeId
    ) {
      //editing my resume
      this.props.editResumeActions.loadResume(props.myResumeId);
    } else if (
      props.location.pathname.includes("myResume") ||
      !props.match.params.id
    ) {
      //creating my resume
      this.props.editResumeActions.clearResume();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      this.loadResume(nextProps);
    }
    let resume = _.cloneDeep(emptyResume);
    let isMine = false;
    let saved = false;
    if (
      nextProps.resume &&
      (nextProps.match.params.id ||
        nextProps.location.pathname.includes("myResume"))
    ) {
      resume = _.cloneDeep(nextProps.resume);

      //add empty objects to empty arrays && convert null to empty string
      Object.keys(resume).forEach((prop) => {
        let propValue = resume[prop];
        if (Array.isArray(propValue) && !propValue.length) {
          if (prop === "references") {
            propValue.push({ contactInfo: [{}] }, { contactInfo: [{}] });
          } else if (prop !== "languages" && prop !== "images") {
            propValue.push({});
          }
        } else if (propValue === null) {
          resume[prop] = "";
        }
      });
      resume.birthday = resume.birthday ? new Date(resume.birthday) : null;
    }
    if (
      nextProps.location.pathname.includes("myResume") ||
      resume.userId === nextProps.userId
    ) {
      isMine = true;
    }
    if (this.state.edited) {
      saved = true;
    }
    this.originalResume = _.cloneDeep(resume);
    this.setState({ resume, isMine, edited: false, errors: {}, saved });
  }

  deleteExtraEmptyObjects(resume) {
    resume = { ...resume };
    let fieldsToExclude = ["references", "languages", "images"];
    Object.keys(resume).forEach((prop) => {
      let propValue = resume[prop];
      if (Array.isArray(propValue) && !fieldsToExclude.includes(prop)) {
        for (let i = propValue.length - 1; i > 0; i--) {
          if (_.isEmpty(propValue[i])) {
            delete propValue[i];
          }
        }
      } else if (prop === "references") {
        for (let i = 0; i < propValue.length; i++) {
          if (_.isEqual(propValue[i], { contactInfo: [{}] })) {
            delete propValue[i];
          }
        }
      }
    });
    return resume;
  }

  removeErrorsForSection(section, errors) {
    Object.keys(this.state.errors).forEach((err) => {
      if (!err.includes(section)) {
        delete errors[err];
      }
    });
    return errors;
  }

  handleChange(values) {
    let edited = !_.isEqual(values, this.originalResume);
    this.setState({ resume: values, edited });
  }

  handleErrors(errors) {
    if (errors["birthday"]) {
      //if dob is in the error state change the message
      errors["birthday"] = "Required";
    }
    if (errors["height"]) {
      //if height is in the error state change the message
      errors["height"] = "Required";
    }
    this.setState({ errors });
  }

  addNestedObject(sectionName) {
    let resume = { ...this.state.resume };
    let section = [...resume[sectionName]];
    if (sectionName === "references") {
      section.push({
        contactInfo: [{}],
      });
    } else {
      section.push({});
    }
    resume[sectionName] = section;
    let edited = !_.isEqual(resume, this.originalResume);
    return this.setState({ resume, edited });
  }

  addDoubleNestedObject(sectionName, index, nestedSectionName) {
    let resume = { ...this.state.resume };
    let section = [...resume[sectionName]];
    let item = { ...section[index] };
    let nestedSection = [...item[nestedSectionName]];
    nestedSection.push({});
    item[nestedSectionName] = nestedSection;
    section[index] = item;
    resume[sectionName] = section;
    let edited = !_.isEqual(resume, this.originalResume);
    return this.setState({ resume, edited });
  }

  async deleteNestedObject(sectionName, index) {
    let resume = { ...this.state.resume };
    let section = [...resume[sectionName]];
    section.splice(index, 1);
    resume[sectionName] = section;
    //delete errors for object that was removed
    let errors = { ...this.state.errors };
    errors = this.removeErrorsForSection(sectionName, errors);
    let edited = !_.isEqual(resume, this.originalResume);
    this.setState({ resume, errors, edited }, () => {
      return true;
    });
  }

  deleteDoubleNestedObject(sectionName, index, nestedSectionName, nestedIndex) {
    let resume = { ...this.state.resume };
    let section = resume[sectionName];
    let item = { ...section[index] };
    let nestedSection = [...item[nestedSectionName]];
    nestedSection.splice(nestedIndex, 1);
    item[nestedSectionName] = nestedSection;
    section[index] = item;
    resume[sectionName] = section;
    let edited = !_.isEqual(resume, this.originalResume);
    return this.setState({ resume, edited });
  }

  getTypeById(id) {
    return this.props.constants.types
      ? this.props.constants.types.find((t) => t.id === id)
      : null;
  }

  changeTypePower(typeId, powerIndex) {
    let resume = { ...this.state.resume };
    let types = resume.types;
    let typeIndex = types.findIndex((t) => t.typeId === typeId);
    if (powerIndex < 0) {
      types = types.filter((t) => t.typeId !== typeId);
    } else if (typeIndex > -1) {
      types[typeIndex].power = powerIndex + 1;
    } else {
      types.push({
        typeId: typeId,
        power: powerIndex + 1,
        type: this.getTypeById(typeId),
      });
    }
    resume.types = types;
    let edited = !_.isEqual(resume, this.originalResume);
    return this.setState({ resume, edited });
  }

  addImage(image) {
    let resume = { ...this.state.resume };
    resume.images.push(image);
    let edited = !_.isEqual(resume, this.originalResume);
    this.setState({ resume, edited });
  }

  removeImage(imageIndex) {
    let resume = { ...this.state.resume };
    resume.images = resume.images.filter((i, index) => index !== imageIndex);
    let edited = !_.isEqual(resume, this.originalResume);
    this.setState({ resume, edited });
  }

  setImageAsMain(imageIndex) {
    let currentImages = [...this.state.resume.images];
    let images = [];
    images.push({ ...currentImages[imageIndex], mainImage: true });
    currentImages.forEach((obj, i) => {
      if (i !== imageIndex) {
        obj.mainImage = false;
        images.push(obj);
      }
    });
    let resume = { ...this.state.resume };
    resume.images = images;
    let edited = !_.isEqual(resume, this.originalResume);
    this.setState({ resume, edited });
  }

  saveResume() {
    let resume = this.state.resume;
    resume = this.deleteExtraEmptyObjects(resume);
    if (this.state.isMine) {
      resume.userId = this.props.userId;
    }
    resume.published = true;
    if (this.state.resume.id) {
      this.props.editResumeActions.saveResume(resume);
    } else {
      this.createNewResume();
    }
  }

  saveAsDraft() {
    let resume = this.state.resume;
    resume = this.deleteExtraEmptyObjects(resume);
    resume.published = false;
    if (this.state.resume.id) {
      this.props.editResumeActions.saveResume(resume);
    } else {
      this.createNewResume();
    }
  }

  createNewResume() {
    if (this.state.isMine) {
      this.props.editResumeActions.createMyResume(this.state.resume);
    } else {
      this.props.editResumeActions.createResume({
        ...this.state.resume,
        published: true,
      });
    }
  }

  changeResumeOwner() {
    let resume = {
      ...this.state.resume,
      userId: !this.state.isMine ? this.props.userId : this.state.resume.userId,
    };
    let edited = !_.isEqual(resume, this.originalResume);
    this.setState({
      isMine: !this.state.isMine,
      edited,
    });
  }

  canBeSavedAsDraft() {
    return (
      this.state.isMine &&
      !this.state.resume.published &&
      this.hasRequiredInfoForDraft(this.state.resume)
    );
  }

  hasRequiredInfoForDraft(resume) {
    return Boolean(resume.firstName && resume.lastName && resume.gender);
  }

  shareResumeModal() {
    this.props.modalActions.setModal("SHARE", {
      shareTo: "user",
      currentResume: this.state.resume,
      closeOnOutsideClick: true,
    });
  }

  shareToGroupModal() {
    this.props.modalActions.setModal("SHARE", {
      shareTo: "group",
      currentResume: this.state.resume,
      closeOnOutsideClick: true,
    });
  }

  render() {
    if (this.props.isLoading && this.props.resume && !this.props.resume.id) {
      return (
        <div className="loader-saving">
          Loading Resume<span>.</span>
          <span>.</span>
          <span>.</span>
        </div>
      );
    }
    if (this.props.errorLoadingResume) {
      return (
        <div className="create-resume-container mui-container-fluid">
          <div className="content header-padding header-padding-subheader">
            <div className="mui-col-md-12">
              <div className="content-padding message-empty">
                <i className="material-icons">not_interested</i>
                <p className="margin-bottom">
                  You do not have permission to edit this resume
                </p>
                <Link to={"/resumes"} className="btn-link btn-link-secondary">
                  Go back to resumes
                </Link>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <ResumeForm
        isLoading={this.props.isLoading}
        resume={this.state.resume}
        constants={this.props.constants}
        isMine={this.state.isMine}
        edited={this.state.edited}
        saved={this.state.saved}
        errors={this.state.errors}
        alert={this.state.alert}
        myResumeId={this.props.myResumeId}
        handleChange={this.handleChange.bind(this)}
        handleError={this.handleErrors.bind(this)}
        saveResume={this.saveResume.bind(this)}
        saveAsDraft={this.saveAsDraft.bind(this)}
        canBeSavedAsDraft={this.canBeSavedAsDraft.bind(this)}
        addImage={this.addImage.bind(this)}
        changeResumeOwner={this.changeResumeOwner.bind(this)}
        deleteExtraEmptyObjects={this.deleteExtraEmptyObjects.bind(this)}
        addNestedObject={this.addNestedObject.bind(this)}
        deleteNestedObject={this.deleteNestedObject.bind(this)}
        addDoubleNestedObject={this.addDoubleNestedObject.bind(this)}
        deleteDoubleNestedObject={this.deleteDoubleNestedObject.bind(this)}
        removeImage={this.removeImage.bind(this)}
        setImageAsMain={this.setImageAsMain.bind(this)}
        changeTypePower={this.changeTypePower.bind(this)}
        shareToGroupModal={this.shareToGroupModal.bind(this)}
        shareResumeModal={this.shareResumeModal.bind(this)}
      />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    editResumeActions: bindActionCreators(editResumeActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    constants: state.constants,
    resume: state.editResume.resume,
    isLoading: state.editResume.isLoading,
    errorLoadingResume: state.editResume.errorLoadingResume,
    myResumeId: state.user.user.hasVerifiedResume || state.user.user.hasDraft,
    userId: state.user.user.id,
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateResumeNew),
);

import React from "react";
import * as ideasActions from "../../actions/ideasActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import FontAwesome from "react-fontawesome";
import ResumeAutosuggest from "../common/resumeAutosuggest";
import IdeaShareModal from "./ideaShareModal";
import PrivateImage from "../common/privateImage";
import Popup from "../common/Popup";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment-timezone";

class ideaModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.isNew
        ? { ideaStateId: props.stateId || 1 }
        : { ...this.props.idea },
      isNew: this.props.isNew || this.props.newFromResume,
      isSharing: false,
      submitted: false,
      alert: null,
    };
    this.timezone = moment.tz.guess();

    this.updateIdeaState = this.updateIdeaState.bind(this);
    this.changeHisStatus = this.changeHisStatus.bind(this);
    this.changeHerStatus = this.changeHerStatus.bind(this);
    this.changeState = this.changeState.bind(this);
    this.saveAutocomplete = this.saveAutocomplete.bind(this);
    this.archiveIdea = this.archiveIdea.bind(this);
    this.isArchiving = false;
  }

  showShareModal() {
    return this.setState({ isSharing: true });
  }

  hideShareModal() {
    return this.setState({ isSharing: false });
  }

  updateIdeaState(event) {
    const field = event.target.name;
    let item = this.state.item;
    item[field] = event.target.value;
    return this.setState({ item, submitted: false });
  }

  saveAutocomplete(field, value) {
    let item = this.state.item;
    item[field] = value;
    return this.setState({ item, submitted: false });
  }

  archiveIdeaAlert() {
    this.setState({
      alert: (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Yes"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title=" Are you sure you want to delete this idea?"
          onConfirm={this.archiveIdea}
          onCancel={() => this.setState({ alert: null })}
        ></SweetAlert>
      ),
    });
  }

  archiveIdea() {
    this.isArchiving = true;
    this.props.actions.archiveIdea(this.state.item.id);
    this.props.removeModal();
  }

  saveIdea(e) {
    e.preventDefault();
    if (this.state.isNew) {
      if (this.state.item.herName && this.state.item.hisName) {
        this.props.actions.createIdea(this.state.item);
      } else {
        return this.setState({ submitted: true });
      }
    }
    if (!this.state.isNew && !this.isArchiving) {
      this.props.actions.editIdea(this.state.item);
    }

    this.props.removeModal();
  }

  componentWillUnmount() {
    //commented out autosave on close modal
    // if(!this.state.isNew && !this.isArchiving){
    //     this.props.actions.editIdea(this.state.item);
    // }
  }

  changeState(stateId) {
    let state = this.props.stateList.find((s) => s.id === stateId);
    this.setState({
      item: {
        ...this.state.item,
        ideaStateId: stateId,
        state: state,
      },
    });
  }

  changeHisStatus(event) {
    let status = Number(event.target.value);
    let hisStatus = this.props.statusList.find((s) => s.id === status);
    this.setState({
      item: {
        ...this.state.item,
        hisStatus: hisStatus,
      },
    });
  }

  changeHerStatus(event) {
    let status = Number(event.target.value);
    let herStatus = this.props.statusList.find((s) => s.id === status);
    this.setState({
      item: {
        ...this.state.item,
        herStatus: herStatus,
      },
    });
  }

  resumeInfo(resume, ideaStateId, status, changeStatusFunction) {
    return (
      <div className="idea-card idea-card-modal">
        <PrivateImage
          fileName={resume.images.length ? resume.images[0].fileLocation : ""}
          id={resume.id}
          section="resume"
          className="idea-card-image"
        />
        <div className="idea-card-info">
          <h5 className="idea-card-title">
            {resume.firstName} {resume.lastName}
          </h5>
          <p className="idea-card-details">
            <span>{resume.age}</span>
            <span>{resume.height}</span>
            <span>{resume.maritalStatus}</span>
          </p>
        </div>
        {this.renderStatusDropdown(ideaStateId, status, changeStatusFunction)}
      </div>
    );
  }

  emptyInfo(name, ideaStateId, status, changeStatusFunction) {
    return (
      <div className="idea-card idea-card-modal">
        <img
          src="/images/large-placeholder.png"
          alt=""
          className="idea-card-image"
        />
        <div className="idea-card-info">
          <h5 className="idea-card-title">{name}</h5>
        </div>
        {this.renderStatusDropdown(ideaStateId, status, changeStatusFunction)}
      </div>
    );
  }

  renderStatusDropdown(ideaStateId, currentStatus, changeStatusFunction) {
    if (ideaStateId === 4) {
      return null;
    }
    let statusList = this.props.statusList;
    let statusDropdown = statusList.map((status) => {
      return (
        <option key={status.id} className={status.color} value={status.id}>
          {status.text}
        </option>
      );
    });
    let value =
      currentStatus && currentStatus.id ? currentStatus.id.toString() : "";
    let color = currentStatus && currentStatus.color ? currentStatus.color : "";
    return (
      <div className="ideas-card-info mui-col-md-12">
        <select
          value={value}
          className={`idea-card-info-dropdown ${color}`}
          onChange={changeStatusFunction}
        >
          <option value="">Choose a status</option>
          {statusDropdown}
        </select>
      </div>
    );
  }

  renderStateButtons(currentStateId) {
    let stateList = this.props.stateList;
    let stateButtons = stateList.map((state) => {
      if (state.id === currentStateId) {
        return (
          <div
            className="button-sliding-bg-active mui-col-md-3"
            key={state.id}
            onClick={this.changeState.bind(null, state.id)}
          >
            <button className="modal-active-btn">{state.ideaState}</button>
          </div>
        );
      }
      return (
        <div
          className="button-sliding-bg mui-col-md-3"
          key={state.id}
          onClick={this.changeState.bind(null, state.id)}
        >
          <button className="modal-disabled-btn">{state.ideaState}</button>
        </div>
      );
    });

    return <div className="modal-subheader mui-row">{stateButtons}</div>;
  }

  renderIdeaCard(item, isNew) {
    if (isNew) {
      return (
        <div className="autosuggest">
          <div className="form-inline-half mui-col-md-6">
            {this.props.idea.hisName ? (
              this.emptyInfo(
                item.hisName,
                item.ideaStateId,
                item.hisStatus,
                this.changeHisStatus,
              )
            ) : (
              <div className="mui-row">
                <legend>
                  Male{" "}
                  {this.state.submitted && !item.hisName ? (
                    <span className="has-error"> Cannot be blank</span>
                  ) : null}
                </legend>
                <ResumeAutosuggest
                  placeholder="Choose Male"
                  name="hisName"
                  value={item.hisName}
                  gender="1"
                  itemSelected={this.saveAutocomplete}
                  onChange={this.saveAutocomplete.bind(null, "hisName")}
                  fieldName="hisResumeId"
                ></ResumeAutosuggest>

                {this.renderStatusDropdown(
                  item.ideaStateId,
                  item.hisStatus,
                  this.changeHisStatus,
                )}
              </div>
            )}
          </div>
          <div className="form-inline-half mui-col-md-6">
            {this.props.idea.herName ? (
              this.emptyInfo(
                item.herName,
                item.ideaStateId,
                item.herStatus,
                this.changeHerStatus,
              )
            ) : (
              <div className="mui-row">
                <legend>
                  Female{" "}
                  {this.state.submitted && !item.herName ? (
                    <span className="has-error"> Cannot be blank</span>
                  ) : null}
                </legend>
                <ResumeAutosuggest
                  placeholder="Choose Female"
                  name="herName"
                  value={item.herName}
                  gender="2"
                  itemSelected={this.saveAutocomplete}
                  onChange={this.saveAutocomplete.bind(null, "herName")}
                  fieldName="herResumeId"
                ></ResumeAutosuggest>

                {this.renderStatusDropdown(
                  item.ideaStateId,
                  item.herStatus,
                  this.changeHerStatus,
                )}
              </div>
            )}
          </div>
        </div>
      );
    }
    return (
      <div className="form-input-margin-bottom mui-row">
        <div className="form-inline-half">
          {item.hisResume && item.hisResume.firstName
            ? this.resumeInfo(
                item.hisResume,
                item.ideaStateId,
                item.hisStatus,
                this.changeHisStatus,
              )
            : this.emptyInfo(
                item.hisName,
                item.ideaStateId,
                item.hisStatus,
                this.changeHisStatus,
              )}
        </div>
        <div className="form-inline-half">
          {item.herResume && item.herResume.firstName
            ? this.resumeInfo(
                item.herResume,
                item.ideaStateId,
                item.herStatus,
                this.changeHerStatus,
              )
            : this.emptyInfo(
                item.herName,
                item.ideaStateId,
                item.herStatus,
                this.changeHerStatus,
              )}
        </div>
      </div>
    );
  }

  render() {
    let item = this.state.item;
    if (this.state.isSharing) {
      return (
        <IdeaShareModal
          goBack={this.hideShareModal.bind(this)}
          idea={this.state.item}
          removeModal={this.props.removeModal}
        />
      );
    }

    return (
      <div className="idea-modal large-modal">
        <div className="mui-col-md-12">
          {this.state.alert}
          {!this.state.isNew ? (
            <div>
              <div className="modal-header mui-row">
                <div className="modal-header-left">
                  <p>Edit Idea</p>
                  <p
                    className="btn-modal"
                    onClick={this.showShareModal.bind(this)}
                  >
                    <Popup
                      trigger={
                        <FontAwesome
                          name="share"
                          onClick={this.unreadChanged}
                        />
                      }
                      content="Share"
                      className="mobile-popup"
                    />
                    <span className="mobile-hidden">Share</span>
                  </p>
                  <p
                    className="btn-modal"
                    onClick={this.archiveIdeaAlert.bind(this)}
                  >
                    <FontAwesome name="trash" />
                    <span className="mobile-hidden">Delete</span>
                  </p>
                </div>
                <i
                  className="material-icons modal-close-icon"
                  onClick={this.props.removeModal.bind(this)}
                >
                  close
                </i>
              </div>
            </div>
          ) : (
            <div className="modal-header mui-row">
              <p>Create a new idea</p>
              <i
                className="material-icons modal-close-icon"
                onClick={this.props.removeModal.bind(this)}
              >
                close
              </i>
            </div>
          )}
          {this.renderStateButtons(item.ideaStateId)}

          <div className="modal-content-container">
            <form id="form">
              {this.renderIdeaCard(item, this.state.isNew)}
              <p>
                Created:{" "}
                {moment
                  .tz(item.dateCreated, this.timezone)
                  .format("MMMM Do YYYY h:mm A")}
              </p>
              {item.lastUpdated &&
              !moment(item.lastUpdate).isSame(item.dateCreated, "hour") ? (
                <p>
                  Updated:{" "}
                  {moment
                    .tz(item.lastUpdated, this.timezone)
                    .format("MMMM Do YYYY h:mm A")}
                </p>
              ) : null}

              <div className="ideas-modal-note mui-row">
                <legend>Note</legend>
                <textarea
                  rows={3}
                  label="Note:"
                  name="noteText"
                  className="note-textarea"
                  placeholder="Add a note here.."
                  value={item.noteText || ""}
                  onChange={this.updateIdeaState}
                >
                  {" "}
                </textarea>
              </div>

              {/*{!this.state.isNew ?*/}
              {/*<Row className="ideas-modal-activity">*/}
              {/*<p>Activity</p>*/}
              {/*<div></div>*/}
              {/*</Row> : null*/}
              {/*}*/}

              <button
                className="btn-flat btn-accentPrimary btn-fullWidth"
                onClick={this.saveIdea.bind(this)}
              >
                Save
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    statusList: state.ideas.statusList,
    stateList: state.ideas.stateList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(ideasActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ideaModal);

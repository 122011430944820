import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ResumeDropdown from "./resumeDropdown";
import { bindActionCreators } from "redux";
import * as groupResumeActions from "../../../actions/groupResumeActions";

class addResumeToGroupModal extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.selectGroup = this.selectGroup.bind(this);
    this.addToGroup = this.addToGroup.bind(this);
    this.state = {
      share: {
        groupId: props.group.id,
        resumeId: null,
        resumeName: "",
        message: "",
      },
      resumeAlreadyAdded: false,
      resumeNotChosen: false,
    };
  }

  addToGroup() {
    if (!this.state.share.resumeId) {
      return this.setState({ resumeNotChosen: true });
    }
    if (this.state.resumeAlreadyAdded) {
      return;
    }
    this.setState({ submitted: true });
    this.props.actions.shareResumeToGroup(
      this.state.share,
      this.props.removeModal,
    );
  }

  selectGroup(resume) {
    let share = this.state.share;
    share.resumeId = resume.id || null;
    share.resumeName = resume.name || "";
    return this.setState({
      share: share,
      resumeAlreadyAdded: resume.addedToGroup,
    });
  }

  onChange(e) {
    const field = e.target.name;
    let share = this.state.share;
    share[field] = e.target.value;
    return this.setState({ share: share });
  }

  render() {
    return (
      <div className="add-resume-to-group-modal groups-modal medium-modal">
        {!this.state.submitted ? (
          <div>
            <div className="mui-row modal-header">
              <p>Add Resume to Group</p>
              <i
                className="material-icons modal-close-icon"
                onClick={this.props.removeModal.bind(this)}
              >
                close
              </i>
            </div>
            <div className="modal-content-container">
              <form className="group-modal-form mui-form" id="form">
                <legend>
                  Resume Name{" "}
                  <span className="has-error">
                    {this.state.resumeNotChosen
                      ? "Please choose a resume from the list"
                      : ""}
                    {this.state.resumeAlreadyAdded
                      ? "This resume cannot be added twice"
                      : ""}
                  </span>
                </legend>
                <ResumeDropdown
                  placeholder="Resume Name"
                  onSelect={this.selectGroup}
                  groupId={this.props.group.id}
                />

                <legend>Message</legend>
                <textarea
                  placeholder="Enter a message for the group admin"
                  name="message"
                  value={this.state.share.message}
                  onChange={this.onChange}
                  className="form-input-margin-bottom"
                />

                <button
                  onClick={this.addToGroup}
                  className="btn-flat btn-success btn-fullWidth"
                  type="button"
                >
                  Add to Group
                </button>
              </form>
            </div>
          </div>
        ) : (
          <div className="modal-response">
            <div className="envelope">
              <div className="envelope-outline">
                <div className="triangle"></div>
              </div>
              <div className="dot dot1"></div>
              <div className="dot dot2"></div>
              <div className="dot dot3"></div>
              <div className="dot dot4"></div>
              <div className="dot dot5"></div>
              <div className="dot dot6"></div>
            </div>
            <p>Resume Added</p>
          </div>
        )}
      </div>
    );
  }
}

addResumeToGroupModal.propTypes = {
  removeModal: PropTypes.func.isRequired,
  group: PropTypes.object.isRequired,
  actions: PropTypes.shape({
    shareResumeToGroup: PropTypes.func.isRequired,
  }),
};

function mapStateToProps(state) {
  return {
    group: state.singleGroup.group,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(groupResumeActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(addResumeToGroupModal);

import * as types from "../actionTypes/userActionTypes";

export default function settingsReducer(state = {}, action) {
  switch (action.type) {
    case types.ACCOUNT_SETTINGS_SAVED:
      return {
        accountSettingsSaved: true,
      };
    case types.ACCOUNT_SETTINGS_ERROR:
      return {
        accountSettingsError: true,
        errorMessage: action.errorMessage,
      };
    case types.SECURITY_SETTINGS_SAVED:
      return {
        securitySettingsSaved: true,
      };
    case types.SECURITY_SETTINGS_ERROR:
      return {
        securitySettingsError: true,
        errorMessage: action.errorMessage,
      };
    case types.ADD_USER_EMAIL_SUCCESS:
      return {
        emailAddedSuccessfully: true,
      };
    case types.ADD_USER_EMAIL_FAIL:
      return {
        errorAddingEmail: true,
        errorMessage: action.errorMessage,
      };
    case types.REMOVE_USER_EMAIL_SUCCESS:
      return {
        emailAddedSuccessfully: true,
      };
    case types.RESEND_EMAIL_VERIFICATION_SUCCESS:
      return {
        emailVerificationSent: true,
      };
    case types.RESEND_EMAIL_VERIFICATION_FAIL:
      return {
        emailVerificationFail: true,
        errorMessage: action.errorMessage,
      };
    default:
      return state;
  }
}

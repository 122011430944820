import React from "react";
import FontAwesome from "react-fontawesome";
import Popup from "../common/Popup";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PrivateImage from "../common/privateImage";
import PrivateLightbox from "../common/privateLightbox";
import moment from "moment";

class SingleResume extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lightboxIsOpen: false,
      currentImage: 0,
    };
    this.openLightbox = this.openLightbox.bind(this);
    this.closeLightbox = this.closeLightbox.bind(this);
    this.goToPrevLightboxImage = this.goToPrevLightboxImage.bind(this);
    this.goToNextLightboxImage = this.goToNextLightboxImage.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.resume.id !== nextProps.resume.id) {
      this.refs.resumeInfo.scrollIntoView();
    }
  }

  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  }

  goToPrevLightboxImage() {
    this.setState({
      currentImage: this.state.currentImage - 1,
    });
  }

  goToNextLightboxImage() {
    this.setState({
      currentImage: this.state.currentImage + 1,
    });
  }

  openLightbox(imageIndex) {
    this.setState({
      currentImage: imageIndex,
      lightboxIsOpen: true,
    });
  }

  render() {
    let resume = this.props.resume;
    const canShowImage = !this.props.user.user.groups?.[0]?.hide_resume_img;
    let fileLocation = "";
    if (canShowImage && resume.images.length) {
      fileLocation = resume.images[0].fileLocation;
    }

    return (
      <div ref="resumeInfo">
        <div className="create-resume-container mui-container">
          <div
            className={`resume-header resume-section ${
              this.props.sidebarView ? "inbox-resume-header" : ""
            }`}
          >
            {resume.engaged ? (
              <div className="container resume-engaged">
                <FontAwesome name="diamond" />
                <span>
                  {" "}
                  Engaged to{" "}
                  {resume.engagedToId ? (
                    <Link to={`/resumes/${resume.engagedToId}`}>
                      {resume.engagedTo}
                    </Link>
                  ) : (
                    resume.engagedTo || "Unknown"
                  )}{" "}
                  on {moment(resume.engageDate).format("MMMM Do YYYY")}
                </span>
              </div>
            ) : (
              ""
            )}
            {!resume.published && !resume.engaged && resume.isMine ? (
              <div className="container resume-engaged">
                <FontAwesome name="file-text-o" />
                <span>
                  {" "}
                  Draft Resume{" "}
                  <Link to={`/resumes/edit/${resume.id}`}>Edit & Publish</Link>
                </span>
              </div>
            ) : (
              ""
            )}
            {fileLocation ? (
              <div className="resume-header-image">
                <PrivateImage
                  fileName={fileLocation}
                  id={resume.id}
                  section="resume"
                  onClick={this.openLightbox.bind(null, 0)}
                />
                <PrivateLightbox
                  currentImage={this.state.currentImage}
                  id={resume.id}
                  section="resume"
                  images={canShowImage ? resume.images : []}
                  isOpen={this.state.lightboxIsOpen}
                  onClose={this.closeLightbox}
                  onClickPrev={this.goToPrevLightboxImage}
                  onClickNext={this.goToNextLightboxImage}
                />
              </div>
            ) : (
              <img
                src="/images/xl-placeholder.png"
                className="resume-header-image"
                height="125"
                width="125"
                alt=""
              />
            )}

            <div className="resume-header-info">
              <h2 className="resume-name large-name resume-name-container">
                {resume.firstName} {resume.lastName}
                {resume.fullName && (
                  <span className="resume-fullName">({resume.fullName})</span>
                )}
                {resume.userId && (
                  <Popup
                    trigger={
                      <span className="resume-info-details">
                        <div className="resume-verified">
                          <FontAwesome name="check" />
                        </div>
                      </span>
                    }
                    content={`Verified Resume`}
                  />
                )}
                {resume.isMine || resume.canEdit ? (
                  <>
                    <span className="resume-info-details">
                      <div className="resume-myResume">
                        <span>My Resume</span>
                      </div>
                    </span>
                    <span className="resume-info-details">
                      <Link
                        to={
                          resume.isMine
                            ? "/myResume"
                            : `/resumes/edit/${resume.id}`
                        }
                      >
                        <div
                          className="resume-myResume"
                          style={{ cursor: "pointer" }}
                        >
                          <Popup
                            trigger={
                              <div>
                                <FontAwesome name="pencil" />
                              </div>
                            }
                            content={
                              resume.isMine
                                ? "This is your resume - Edit?"
                                : "You added this resume - Edit?"
                            }
                          />
                        </div>
                      </Link>
                    </span>
                  </>
                ) : (
                  ""
                )}
              </h2>
              <div className="resume-header-details">
                <p>
                  <span className="resume-header-detail-title">Age</span>
                  <span>{resume.age || "-"}</span>
                </p>
                <p>
                  <span className="resume-header-detail-title">Height</span>
                  <span>{resume.height}</span>
                </p>
                <p>
                  <span className="resume-header-detail-title">
                    Marital Status
                  </span>
                  <span>
                    {resume.maritalStatus}{" "}
                    {resume.numberChildren > 0
                      ? `/ ${resume.numberChildren}`
                      : ""}
                  </span>
                </p>
                {resume.engaged && (
                  <Popup
                    trigger={
                      <p>
                        <span className="resume-header-detail-title">
                          Engaged Age
                        </span>
                        <span>{resume.ageAtEngagement}</span>
                      </p>
                    }
                    content={`Since age automatically increases each year, this shows the age when the resume was marked as engaged.`}
                  />
                )}
              </div>
            </div>
            {this.props.sidebarView ? (
              <div className="secondary-header-buttons">
                <p className="btn-flat btn-primaryAccent">
                  <Link key="resumes" to={`/resumes/${resume.id}`}>
                    View Resume <FontAwesome name="external-link" />
                  </Link>
                </p>
              </div>
            ) : (
              <div className="resume-id">
                <Popup
                  trigger={
                    <div className="container">
                      <span>ID: {resume.id}</span>
                    </div>
                  }
                  content={`Resume ID`}
                />
                <span>
                  Updated: {moment(resume.updatedAt).format("MM/D/YYYY")}
                </span>
              </div>
            )}
          </div>
          <div className="content single-resume-content">
            <div className="mui-col-md-12">
              <div className="panelContainer resume-section">
                <div className="resume create-resume">
                  <div className="resume-content">
                    <div className="resume-column-left">
                      <div className="resume-section">
                        <div className="resume-title-header">
                          <h3>Personal Info</h3>
                        </div>
                        <div className="resume-content">
                          <table className="resume-table">
                            <tbody>
                              <tr>
                                <td className="resume-table-title">Gender:</td>
                                <td className="details-text">
                                  {resume.gender}
                                </td>
                              </tr>
                              {resume.ethnicity ? (
                                <tr>
                                  <td className="resume-table-title">
                                    Ethnicity:
                                  </td>
                                  <td className="details-text">
                                    {resume.ethnicity}
                                  </td>
                                </tr>
                              ) : null}
                              {resume.languages.length ? (
                                <tr>
                                  <td className="resume-table-title">
                                    Languages:
                                  </td>
                                  {resume.languages.map((l) => (
                                    <td
                                      key={`language${l.id}`}
                                      className="details-text language"
                                    >
                                      {l.text}
                                    </td>
                                  ))}
                                </tr>
                              ) : null}
                              {resume.religious ? (
                                <tr>
                                  <td className="resume-table-title">
                                    Religious Background:
                                  </td>
                                  <td className="details-text">
                                    {resume.religious}
                                  </td>
                                </tr>
                              ) : null}
                              {resume.gender === "Male" ? (
                                <tr>
                                  <td className="resume-table-title">Cohen:</td>
                                  <td className="details-text">
                                    {resume.isCohen ? "Yes" : "--"}
                                  </td>
                                </tr>
                              ) : null}
                              <tr>
                                <td className="resume-table-title">Address:</td>
                                <td className="details-text">
                                  {resume.address}
                                  <br />
                                  {resume.city}, {resume.state} {resume.country}
                                </td>
                              </tr>
                              {resume.hometown ? (
                                <tr>
                                  <td className="resume-table-title">
                                    Hometown:
                                  </td>
                                  <td className="details-text">
                                    {resume.hometown}
                                  </td>
                                </tr>
                              ) : null}
                              {resume.relocate ? (
                                <tr>
                                  <td className="resume-table-title">
                                    Willing to Relocate:
                                  </td>
                                  <td className="details-text">
                                    {resume.relocate}
                                  </td>
                                </tr>
                              ) : null}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {resume.types.length ? (
                        <div className="resume-section">
                          <div className="resume-title-header">
                            <h3>Types</h3>
                          </div>
                          <div className="resume-content">
                            <table className="resume-table">
                              <tbody>
                                {resume.types &&
                                  resume.types.map((type) => {
                                    return (
                                      <tr key={`type${type.id}`}>
                                        <td className="resume-table-title">
                                          {type.type.text}:
                                        </td>
                                        <td className="type-amount">
                                          {[...Array(10)].map((x, i) => (
                                            <FontAwesome
                                              key={`type${type.id}-${i}`}
                                              name="circle"
                                              className={
                                                i < type.power ? "filled" : ""
                                              }
                                            />
                                          ))}
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      ) : null}

                      {resume.personalDescription ? (
                        <div className="resume-section">
                          <div className="resume-title-header">
                            <h3 ref="personalDescription">
                              Personal Description
                            </h3>
                          </div>
                          <div className="resume-content">
                            <p>{resume.personalDescription}</p>
                          </div>
                        </div>
                      ) : null}

                      {resume.lookingFor ? (
                        <div className="resume-section">
                          <div className="resume-title-header">
                            <h3 ref="personalDescription">Looking For</h3>
                          </div>
                          <div className="resume-content">
                            <p>{resume.lookingFor}</p>
                          </div>
                        </div>
                      ) : null}

                      {resume.familyInfo && resume.familyInfo.info ? (
                        <div className="resume-section">
                          <div className="resume-title-header">
                            <h3>Family</h3>
                          </div>
                          <div className="resume-content">
                            <ul>
                              <li>
                                <p className="margin-top preserve-whitespace">
                                  {resume.familyInfo.info}
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                      ) : null}

                      {resume.shuls.length ? (
                        <div className="resume-section">
                          <div className="resume-title-header">
                            <h3 ref="test">Shul</h3>
                          </div>
                          <div className="resume-content">
                            {resume.shuls &&
                              resume.shuls.map((shul) => {
                                return (
                                  <div key={`shul${shul.id}`}>
                                    <p className="bold no-margin-bottom">
                                      {shul.name}
                                    </p>
                                    <p className="rabbi-name no-margin-bottom">
                                      {shul.rabbi}
                                    </p>
                                    <p className="no-margin-bottom">
                                      {shul.rabbiPhone}
                                    </p>
                                    <p className="details-text">
                                      {shul.details}
                                    </p>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      ) : null}

                      {resume.camps.length ? (
                        <div className="resume-section">
                          <div className="resume-title-header">
                            <h3 ref="test">Camps</h3>
                          </div>
                          <div className="resume-content">
                            {resume.camps &&
                              resume.camps.map((camp) => {
                                return (
                                  <div key={`camp${camp.id}`}>
                                    <p className="bold inline no-margin-bottom">
                                      {camp.name}
                                    </p>
                                    <p className="details-text">
                                      {camp.details}
                                    </p>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      ) : null}

                      {resume.volunteer.length ? (
                        <div className="resume-section">
                          <div className="resume-title-header">
                            <h3 ref="test">
                              Other / Organization / Volunteer Work
                            </h3>
                          </div>
                          <div className="resume-content">
                            {resume.volunteer &&
                              resume.volunteer.map((item) => {
                                return (
                                  <div key={`volunteer${item.id}`}>
                                    <p className="bold inline no-margin-bottom">
                                      {item.name}
                                    </p>
                                    <p className="details-text">
                                      {item.details}
                                    </p>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      ) : null}
                    </div>

                    <div className="resume-column-right">
                      {canShowImage && resume.images.length > 1 ? (
                        <div className="resume-section">
                          <div className="resume-title-header">
                            <h3>Photos</h3>
                          </div>
                          <div className="resume-content">
                            <div className="resume-photos">
                              {resume.images.map((image, i) => {
                                if (i > 0) {
                                  return (
                                    <div
                                      className="resume-pic"
                                      key={`image${image.id}`}
                                    >
                                      <PrivateImage
                                        fileName={image.fileLocation}
                                        id={resume.id}
                                        section="resumeSmall"
                                        onClick={this.openLightbox.bind(
                                          null,
                                          i,
                                        )}
                                      />
                                    </div>
                                  );
                                } else {
                                  return null;
                                }
                              })}
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {resume.displayPersonalContactInfo &&
                      (resume.mobilePhone ||
                        resume.homePhone ||
                        resume.email ||
                        resume.shidduchContactInfo) ? (
                        <div className="resume-section">
                          <div className="resume-title-header">
                            <h3>{resume.firstName}'s Contact Info</h3>
                          </div>
                          <div className="resume-content">
                            <p className="my-contact-info no-margin-bottom">
                              {resume.mobilePhone}
                            </p>
                            <p className="my-contact-info no-margin-bottom">
                              {resume.homePhone}
                            </p>
                            <p className="my-contact-info">{resume.email}</p>
                            <p>{resume.shidduchContactInfo}</p>
                          </div>
                        </div>
                      ) : null}
                      {resume.contactName ||
                      resume.contactPhone ||
                      resume.contactEmail ? (
                        <div className="resume-section">
                          <div className="resume-title-header">
                            <h3 className="inline">Resume Contact Info</h3>
                          </div>
                          <div className="resume-content">
                            <p className="bold inline no-margin-bottom">
                              {resume.contactName}
                              <span className="reference-relationship">
                                {resume.contactRelationship}
                              </span>
                            </p>
                            <p className="no-margin-bottom">
                              {resume.contactPhone}
                            </p>
                            <p>{resume.contactEmail}</p>
                          </div>
                        </div>
                      ) : null}
                      {resume.shadchanName && (
                        <div className="resume-section">
                          <div className="resume-title-header">
                            <h3 className="inline">Shadchan Contact Info</h3>
                          </div>
                          <div className="resume-content">
                            <p className="bold inline no-margin-bottom">
                              {resume.shadchanName}
                            </p>
                            <p className="no-margin-bottom">
                              {resume.shadchanPhone}
                            </p>
                            <p>{resume.shadchanEmail}</p>
                            <p>{resume.shadchanDetails}</p>
                          </div>
                        </div>
                      )}

                      {resume.occupation && resume.occupation.id ? (
                        <div className="resume-section">
                          <div className="resume-title-header">
                            <h3>Occupation</h3>
                          </div>
                          <div className="resume-content">
                            <p className="bold inline no-margin-bottom">
                              {resume.occupation.name}
                            </p>
                            <p className="details-text">
                              {resume.occupation.details}
                            </p>
                          </div>
                        </div>
                      ) : null}

                      {resume.education.length ? (
                        <div className="resume-section">
                          <div className="resume-title-header">
                            <h3>Education</h3>
                          </div>
                          <div className="resume-content">
                            {resume.education &&
                              resume.education.map((ed) => {
                                return (
                                  <div
                                    key={`ed${ed.id}`}
                                    className="resume-timeline"
                                  >
                                    <FontAwesome
                                      name="circle"
                                      className="resume-circle"
                                    />
                                    <p className="bold inline">{ed.name}</p>
                                    <p className="details-text">{ed.details}</p>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      ) : null}

                      {resume.references.length ? (
                        <div className="resume-section">
                          <div className="resume-title-header">
                            <h3>References</h3>
                          </div>
                          <div className="resume-content">
                            <ul>
                              {resume.references.map((reference) => {
                                return (
                                  <li key={`reference${reference.id}`}>
                                    <p className="bold inline no-margin-bottom">
                                      {reference.name}
                                      <span className="reference-relationship">
                                        {reference.referenceType}
                                      </span>
                                    </p>
                                    {reference.contactInfo &&
                                      reference.contactInfo.map((info) => {
                                        return (
                                          <p
                                            key={`refInfo${info.id}`}
                                            className="no-margin-bottom"
                                          >
                                            {info.contactText}
                                          </p>
                                        );
                                      })}
                                    <p className="details-text">
                                      {reference.details}
                                    </p>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, {})(SingleResume);

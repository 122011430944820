import * as types from "../actionTypes/notificationActionTypes";
import { apiAuthRequest } from "./helpers";

export function loadNotifications() {
  return async (dispatch) => {
    let result = await apiAuthRequest("get", "/notifications/getByUser");
    if (result.success) {
      dispatch({
        type: types.LOAD_NOTIFICATIONS_SUCCESS,
        notifications: result.data,
      });
    }
  };
}

export function markNotificationAsRead(notificationId) {
  return async (dispatch) => {
    let result = await apiAuthRequest("post", "/notifications/markAsRead", {
      notificationId,
    });
    if (result.success) {
      dispatch({
        type: types.NOTIFICATION_READ_SUCCESS,
        notificationId,
      });
    }
  };
}

export function clearAllNotifications() {
  return async (dispatch) => {
    let result = await apiAuthRequest("post", "/notifications/markAllAsRead");
    if (result.success) {
      dispatch({
        type: types.CLEAR_NOTIFICATIONS_SUCCESS,
      });
    }
  };
}

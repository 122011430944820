import * as types from "../actionTypes/ideasActionTypes";
import { apiAuthRequest } from "./helpers";
import { toastr } from "react-redux-toastr";

export function loadIdeas() {
  return async (dispatch) => {
    let result = await apiAuthRequest("get", "/ideas/getAllIdeas");
    if (result.success) {
      dispatch({
        type: types.GET_IDEAS_SUCCESS,
        ideas: result.data,
      });
    } else {
      console.error(result.data);
      toastr.error(
        "There was an error loading your ideas, try again soon or contact support",
      );
    }
  };
}

export function searchIdeas(query) {
  return {
    type: types.SEARCH_IDEAS,
    query,
  };
}

export function loadConstants() {
  return async (dispatch) => {
    let result = await apiAuthRequest("get", "/ideas/getConstants");
    if (result.success) {
      dispatch({
        type: types.GET_IDEAS_CONSTANTS_SUCCESS,
        constants: result.data,
      });
    }
  };
}

export function moveCardFrontEnd(lastX, lastY, nextX, nextY, stateId) {
  return {
    type: types.MOVE_CARD,
    lastX,
    lastY,
    nextX,
    nextY,
    nextStateId: stateId,
  };
}

export function moveCard(
  cardId,
  nextBelowId,
  nextAboveId,
  lastX,
  lastY,
  nextX,
  nextY,
) {
  return async (dispatch) => {
    const stateId = nextX + 1; //states start at 1 instead of 0
    dispatch(moveCardFrontEnd(lastX, lastY, nextX, nextY, stateId));

    let result = await apiAuthRequest("post", "/ideas/moveCard", {
      ideaId: cardId,
      nextBelowId: nextBelowId,
      nextAboveId: nextAboveId,
      stateId: stateId,
    });
    if (!result.success) {
      console.error(result.data);
      toastr.error("Something went wrong, your changes may not be saved");
    }
  };
}

export function editIdea(idea) {
  return async (dispatch) => {
    let result = await apiAuthRequest("post", "/ideas/editIdea", idea);
    if (result.success) {
      dispatch({
        type: types.EDIT_IDEA_SUCCESS,
        idea: result.data,
      });
    } else {
      console.error(result.data);
      toastr.error("Something went wrong, idea was not saved");
    }
  };
}

export function createIdea(idea) {
  return async (dispatch) => {
    let result = await apiAuthRequest("post", "/ideas/createIdea", idea);
    if (result.success) {
      dispatch({
        type: types.CREATE_IDEA_SUCCESS,
        idea: result.data,
      });
    } else {
      console.error(result.data);
      toastr.error("Something went wrong, idea was not saved");
    }
  };
}

export function archiveIdea(ideaId) {
  return async (dispatch) => {
    let result = await apiAuthRequest("post", "/ideas/archiveIdea", {
      id: ideaId,
    });
    if (result.success) {
      dispatch({
        type: types.ARCHIVE_IDEA_SUCCESS,
        ideaId,
      });
    } else {
      console.error(result.data);
      toastr.error("Error saving idea");
    }
  };
}

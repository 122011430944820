import * as types from "../actionTypes/userActionTypes";
import * as resumeTypes from "../actionTypes/resumeActionTypes";

export default function userReducer(state = { isLoading: true }, action) {
  switch (action.type) {
    case types.USER_NOT_LOGGED_IN:
      if (window.$crisp) {
        window.$crisp.push(["do", "chat:show"]);
      }
      return {
        isLoading: false,
      };
    case types.USER_LOGIN_START:
      return {
        errorMessage: "",
        isAuthenticated: false,
        isLoggingIn: true,
      };
    case types.USER_LOGIN_SUCCESS:
      if (window.bugyard) {
        window.bugyard("setUser", {
          email: action.user.email, // user email, required
          name: action.user.firstName + " " + action.user.lastName, // full name
          id: action.user.id, // user id
        });
      }

      if (window.$crisp) {
        //Set user email, async-safe
        window.$crisp.push(["set", "user:email", action.user.email]);
        window.$crisp.push([
          "set",
          "user:nickname",
          action.user.firstName + " " + action.user.lastName,
        ]);
        window.$crisp.push(["do", "chat:hide"]);
        window.$crisp.push([
          "on",
          "message:received",
          () => {
            window.$crisp.do("chat:show");
          },
        ]);
        window.$crisp.push([
          "on",
          "chat:closed",
          () => {
            window.$crisp.do("chat:hide");
          },
        ]);
      }

      return {
        unconfirmedUserId: null,
        errorMessage: "",
        user: action.user,
        isLoggingIn: false,
        isAuthenticated: true,
      };
    case types.USER_LOGIN_ERROR:
      return {
        unconfirmedUserId: action.data.unconfirmedUserId,
        errorMessage: action.data.message,
        isLoggingIn: false,
        isAuthenticated: false,
      };
    case types.REGISTER_USER_START:
      return {
        errorMessage: "",
        isRegistered: false,
        isRegistering: true,
      };
    case types.REGISTER_USER_SUCCESS:
      return {
        unconfirmedUserId: action.unconfirmedUserId,
        errorMessage: "",
        isRegistered: true,
        isRegistering: false,
      };
    case types.REGISTER_USER_ERROR:
      return {
        errorMessage: action.errorMessage,
        isRegistered: false,
        isRegistering: false,
      };
    case types.REGISTER_USER_DONE:
      return {};
    case types.CONFIRM_USER_FAIL:
      return {
        errorConfirmingUser: true,
        canResendConfirmation: action.data.canResend,
        confirmationErrorMessage: action.data.message,
      };
    case types.CONFIRM_USER_SUCCESS:
      return {
        userConfirmed: true,
      };
    case types.RESEND_CONFIRMATION_SUCCESS:
      return {
        ...state,
        confirmationResent: true,
        message: action.data.message,
        confirmedAlready: action.data.confirmedAlready,
      };
    case types.ACCOUNT_SETTINGS_SAVED:
      return {
        ...state,
        user: action.user,
      };
    case types.ADD_USER_EMAIL_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          emails: [...state.user.emails, action.userEmail],
        },
      };
    case types.REMOVE_USER_EMAIL_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          emails: state.user.emails.filter((e) => e.id !== action.userEmailId),
        },
      };
    case types.RESEND_EMAIL_VERIFICATION_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          emails: state.user.emails.map((e) => {
            if (e.id === action.userEmailId) {
              e.confirmationResent = true;
            }
            return e;
          }),
        },
      };
    case types.INVITE_USER_START:
      return {
        ...state,
        inviteUserSuccess: false,
      };
    case types.INVITE_USER_SUCCESS:
      return {
        ...state,
        inviteUserSuccess: true,
      };
    case resumeTypes.SAVE_MY_RESUME_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          hasVerifiedResume: action.resume.id,
          resume: action.resume,
        },
      };
    case types.UPLOAD_USER_ID_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          verificationImage: action.verificationImage,
        },
      };
    case types.USER_LOGOUT:
      if (window.$crisp) {
        window.$crisp.push(["do", "chat:show"]);
        window.$crisp.do("session:reset", [true]);
      }

      if (window.bugyard) {
        window.bugyard("freeUser");
      }
      return {
        isLoading: false,
      };
    default:
      return state;
  }
}

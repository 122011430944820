import * as types from "../actionTypes/groupActionTypes";

export default function editGroupReducer(state = {}, action) {
  switch (action.type) {
    case types.GET_GROUP_EDIT_START:
      return {
        isLoadingGroup: true,
      };
    case types.GET_GROUP_EDIT_FAIL:
      return {
        isLoadingGroup: false,
        errorMessage: action.errorMessage,
      };
    case types.GET_GROUP_EDIT_SUCCESS:
      return {
        ...action.group,
      };
    case types.CREATE_GROUP_START:
    case types.EDIT_GROUP_START:
      return {
        ...state,
        savingGroup: true,
      };
    case types.CREATE_GROUP_FAIL:
    case types.EDIT_GROUP_FAIL:
      return {
        ...state,
        savingGroup: false,
        errorMessage: action.errorMessage,
      };
    case types.CREATE_GROUP_SUCCESS:
    case types.EDIT_GROUP_SUCCESS:
      return {};
    default:
      return state;
  }
}

import { combineReducers } from "redux";
import modals from "./modalReducer";
import { USER_LOGOUT } from "../actionTypes/userActionTypes";
import user from "./userReducer";
import resumes from "./resumeReducer";
import conversations from "./conversationReducer";
import singleConversation from "./singleConversationReducer";
import sidebarResume from "./sidebarResumeReducer";
import singleResume from "./singleResumeReducer";
import sidebarConversation from "./sidebarConversationReducer";
import editResume from "./editResumeReducer";
import groups from "./groupReducer";
import singleGroup from "./singleGroupReducer";
import editGroup from "./groupEditReducer";
import ideas from "./ideasReducer";
import constants from "./constantsReducer";
import userTags from "./userTagsReducer";
import notifications from "./notificationsReducer";
import welcome from "./welcomeReducer";
import settings from "./settingsReducer";
import ui from "./uiReducer";
import { reducer as toastrReducer } from "react-redux-toastr";

const appReducer = combineReducers({
  modals,
  user,
  resumes,
  conversations,
  singleConversation,
  sidebarResume,
  singleResume,
  sidebarConversation,
  editResume,
  groups,
  singleGroup,
  editGroup,
  ideas,
  constants,
  userTags,
  notifications,
  welcome,
  settings,
  ui,
  toastr: toastrReducer,
});

const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;

import * as types from "../actionTypes/tagsActionTypes";

export default function tagsReducer(state = [], action) {
  switch (action.type) {
    case types.LOAD_TAGS_SUCCESS:
      return action.tags;
    case types.CREATE_TAG_SUCCESS:
      return [...state, action.tag];
    default:
      return state;
  }
}

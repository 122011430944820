import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

class TermsPage extends Component {
  render() {
    return (
      <div className="page-account terms" style={{ height: "100vh" }}>
        <Helmet>
          <title>Terms | ZivugTech</title>
        </Helmet>
        <div className="page-account-container-large">
          {/* <Link to={"/login"}>
            <img src="/images/logo.png" alt="" className="page-account-logo" />
          </Link> */}
          <div id="form" className="page-account-content mui--z1">
            <h3>Terms</h3>
            <p>Please contact ralph@zivugtech.org for terms</p>
          </div>
        </div>
      </div>
    );
  }
}

export default TermsPage;

export const fullName =
  "Including your Hebrew name is helpful for families that do not allow children to marry someone with the same name as a parent.";
export const dateOfBirth =
  "Only your age will be displayed on your resume. We ask for DOB so that our system can keep your age accurate.";
export const education =
  "Including the years you attended makes it easier for people to research and find people in common.";
export const family =
  "Father's Name and Occupation, Mother's Name and Occupation, Siblings' Names and Ages (School/Occupation Names and Occupations) etc...";
export const description =
  "Write 3-6 sentences about you. Include information on any of the following: your background/childhood/upbringing, personality, character traits, interests, occupation, hashkafa, vision for married life, and or anything else you think a shadchan would need to know.";
export const lookingFor =
  "Write 3-6 sentences describing the person you are looking to marry. Include information on any of the following: their character traits, occupation, personality, hashkafa, and anything else you think a shadchan would need to know.";
export const shadchanContactInfo =
  "The name and contact info of a shadchan you work with is very helpful for those researching.";
export const references = "Two references are required.";
export const types = "Please select a level from 1-10 for each type";

import * as types from "../actionTypes/sidebarConversationActionTypes";
import * as conversationTypes from "../actionTypes/conversationActionTypes";

export default function sidebarConversationReducer(
  state = { conversation: {} },
  action,
) {
  switch (action.type) {
    case types.LOAD_SIDEBAR_CONVERSATION_SUCCESS:
      return {
        conversation: action.conversation,
        isLoading: false,
      };
    case types.LOAD_SIDEBAR_CONVERSATION_START:
      return {
        conversation: state,
        isLoading: true,
      };
    case types.SEND_MESSAGE_ON_SIDEBAR_SUCCESS:
      let conversation = { ...state.conversation };
      conversation.messages.push(action.message);
      return {
        ...state,
        conversation: {
          ...conversation,
          read: true,
          lastViewedByMe: new Date(),
        },
      };
    case conversationTypes.NEW_MESSAGE_RECEIVED:
      let isCurrentConversation =
        state.conversation &&
        state.conversation.id === action.data.conversationId;
      return {
        ...state,
        conversation: {
          ...state.conversation,
          read: isCurrentConversation ? false : state.conversation.read,
          messages: isCurrentConversation
            ? [...state.conversation.messages, action.data]
            : state.conversation
            ? state.conversation.messages
            : null,
        },
      };
    default:
      return state;
  }
}

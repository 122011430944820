import * as types from "../actionTypes/resumeActionTypes";
import { apiAuthRequest } from "./helpers";
import history from "../history";
import { toastr } from "react-redux-toastr";

function saveResumeSuccess(resume) {
  return {
    type: types.SAVE_RESUME_SUCCESS,
    resume,
    analytics: {
      event: "create_resume",
      params: {
        resume_id: resume.id,
      },
    },
  };
}

function saveMyResumeSuccess(resume) {
  return {
    type: types.SAVE_MY_RESUME_SUCCESS,
    resume,
    analytics: {
      event: "create_my_resume",
      params: {
        resume_id: resume.id,
      },
    },
  };
}

function createResumeStart(resume) {
  return {
    type: types.CREATE_RESUME_START,
    resume,
  };
}

export function loadResume(id) {
  return async (dispatch) => {
    dispatch({ type: types.LOAD_EDIT_RESUME_START });

    let result = await apiAuthRequest("get", "/resumes/getResumeToEdit", {
      id,
    });
    if (result.success) {
      // if (result.data.isMine) {
      //   history.push("/myResume");
      // }
      dispatch({
        type: types.LOAD_EDIT_RESUME_SUCCESS,
        resume: result.data,
      });
    } else {
      dispatch({ type: types.LOAD_EDIT_RESUME_FAIL });
    }
  };
}

export function clearResume() {
  return async (dispatch) => {
    dispatch({
      type: types.CLEAR_EDIT_RESUME,
    });
  };
}

export function saveResume(resume) {
  return async (dispatch) => {
    dispatch({
      type: types.SAVE_RESUME_START,
      resume,
    });

    let result = await apiAuthRequest("post", "/resumes/editResume", resume);
    if (result.success) {
      if (result.data.isMine) {
        dispatch(saveMyResumeSuccess(result.data));
        history.push("/myResume");
      } else {
        dispatch(saveResumeSuccess(result.data));
      }
    } else {
      toastr.error("Error saving resume");
      console.error(result.data);
    }
  };
}

export function createResume(resume) {
  return async (dispatch, getState) => {
    dispatch(createResumeStart(resume));

    let result = await apiAuthRequest(
      "post",
      "/resumes/unverified/createResume",
      resume,
    );
    if (result.success) {
      dispatch(saveResumeSuccess(result.data));
      const { user: { user: { group_id } = {} } = {} } = getState();
      await apiAuthRequest("post", "/groupResumes/addResumeToGroup", {
        groupId: group_id,
        resumeId: result.data.id,
        // resumeName: "",
        message: "",
      });
      history.push("/resumes/edit/" + result.data.id);
    } else {
      toastr.error("Error saving resume");
      console.error(result.data);
    }
  };
}

export function createMyResume(resume) {
  return async (dispatch) => {
    dispatch(createResumeStart(resume));

    let result = await apiAuthRequest(
      "post",
      "/resumes/verified/createResume",
      resume,
    );
    if (result.success) {
      dispatch(saveMyResumeSuccess(result.data));
      history.push("/myResume");
    } else {
      toastr.error("Error saving resume");
      console.error(result.data);
    }
  };
}

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SweetAlert from "react-bootstrap-sweetalert";
import FontAwesome from "react-fontawesome";

import * as modalActions from "../../../actions/modalActions";
import * as groupPageActions from "../../../actions/groupPageActions";
import * as groupMemberActions from "../../../actions/groupMemberActions";
import * as groupResumeActions from "../../../actions/groupResumeActions";

import Popup from "../../common/Popup";

class GroupHeader extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {};

    this.reportGroupModal = this.reportGroupModal.bind(this);
    this.inviteMemberToGroup = this.inviteMemberToGroup.bind(this);
    this.showShareResumeToGroupModal = this.showShareResumeToGroupModal.bind(
      this,
    );
    this.removeReportGroupAlert = this.removeReportGroupAlert.bind(this);
    this.removeReportGroup = this.removeReportGroup.bind(this);
    this.leaveGroup = this.leaveGroup.bind(this);
    this.alertLeaveGroup = this.alertLeaveGroup.bind(this);
  }

  reportGroupModal() {
    this.props.modalActions.setModal("FLAG", {
      flaggedId: this.props.group.id,
      flaggedName: this.props.group.name,
      flaggedType: "Group",
      flagAction: this.props.actions.flagGroup.bind(this),
      closeOnOutsideClick: true,
    });
  }

  inviteMemberToGroup() {
    this.props.modalActions.setModal("GROUPS_INVITE_MEMBER", {
      myAccessLevel: this.props.group.memberAccessLevel,
      groupApproved: this.props.group.approved,
      closeOnOutsideClick: true,
    });
  }

  showShareResumeToGroupModal() {
    this.props.modalActions.setModal("ADD_RESUME_TO_GROUP", {
      closeOnOutsideClick: true,
    });
  }

  removeReportGroupAlert(groupId) {
    this.props.addAlert(
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        title=" Are you sure you want to unflag this group?"
        onConfirm={() => this.removeReportGroup(groupId)}
        onCancel={this.props.clearAlert}
      ></SweetAlert>,
    );
  }

  removeReportGroup(groupId) {
    this.props.actions.unflagGroup(groupId);
    this.props.clearAlert();
  }

  leaveGroup() {
    this.props.addAlert(
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes, leave group!"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        title="Are you sure?"
        onConfirm={this.alertLeaveGroup}
        onCancel={this.props.clearAlert}
      ></SweetAlert>,
    );
  }

  alertLeaveGroup() {
    this.props.groupMemberActions.leaveMemberFromGroup(this.props.group.id);
    this.props.clearAlert();
  }

  render() {
    let { group } = this.props;

    let addResumeButton;
    if (group.resumeAdded && this.props.hasVerifiedResume) {
      if (group.resumeAdded.added && !group.resumeAdded.approved) {
        addResumeButton = (
          <Popup
            trigger={
              <div className="panel-action-pending">
                <FontAwesome name="file-text" />
                <p>Resume Pending</p>
              </div>
            }
            content={
              "Your resume is waiting for approval from the group admin before being added to this group"
            }
          />
        );
      } else if (group.resumeAdded.added && group.resumeAdded.approved) {
        addResumeButton = (
          <Popup
            trigger={
              <div className="panel-action-active">
                <FontAwesome name="file-text" />
                <p>My Resume Added</p>
              </div>
            }
            content={"Your resume has been added to this group"}
          />
        );
      }
    } else if (this.props.hasVerifiedResume) {
      addResumeButton = (
        <Popup
          trigger={
            <div
              onClick={() =>
                this.props.groupResumeActions.addMyResumeToGroup(group.id)
              }
            >
              <FontAwesome name="file-text" />
              <p>Add My Resume</p>
            </div>
          }
          content={
            "Click here to share your resume with this group. Once it's shared it becomes visible to any members of the group and they can share it via Inbox or Groups"
          }
        />
      );
    }

    let joinGroupButton;
    if (group.memberAccessLevel < 5) {
      if (group.approved && group.members.length > 2) {
        joinGroupButton = (
          <div onClick={this.leaveGroup} className="panel-action">
            <FontAwesome name="sign-out" />
            <p>Leave Group</p>
          </div>
        );
      }
    } else if (group.memberRequestPending && group.privacy === 1) {
      joinGroupButton = (
        <Popup
          trigger={
            <div className="panel-action panel-action-pending">
              <FontAwesome name="user-plus" />
              <p>Member Request Pending</p>
            </div>
          }
          content={`Your request is awaiting approval from a group admin`}
        />
      );
    } else if (
      group.memberAccessLevel > 4 &&
      group.approved &&
      !group.invite &&
      group.privacy === 1
    ) {
      joinGroupButton = (
        <Popup
          trigger={
            <div className="panel-action">
              <div
                onClick={() =>
                  this.props.groupMemberActions.requestToJoinGroup(group.id)
                }
              >
                <FontAwesome name="user-plus" />
                <p>Request to join Group</p>
              </div>
            </div>
          }
          content={`Click here to request access to a group. An admin will review and process your request`}
        />
      );
    }

    let inviteToGroupButton;
    if (group.memberAccessLevel <= 2) {
      inviteToGroupButton = (
        <Popup
          trigger={
            <div className="panel-action">
              <div onClick={this.inviteMemberToGroup}>
                <FontAwesome name="user-plus" />
                <p>
                  Invite {group.approved ? "member" : "owner"} to {group.name}
                </p>
              </div>
            </div>
          }
          content={`Invite user to join '${group.name}' group`}
        />
      );
    } else {
      inviteToGroupButton = "";
    }

    return (
      <div className="container overflow-auto">
        <div className="panel-actions panel-actions-fixed">
          {this.props.displayMobile ? (
            <div
              onClick={this.props.switchMobileTab}
              className="panel-action all-resumes"
            >
              <FontAwesome name="arrow-left" /> <p>Back to list</p>
            </div>
          ) : null}
          {group.approved ? (
            <div className="panel-action">{addResumeButton}</div>
          ) : (
            ""
          )}
          {group.approved ? (
            <Popup
              trigger={
                <div
                  className="panel-action"
                  onClick={this.showShareResumeToGroupModal}
                >
                  <FontAwesome name="file" />
                  <p>
                    {group.memberAccessLevel < 4
                      ? "Add a Resume"
                      : "Send a resume to Group"}
                  </p>
                </div>
              }
              content={
                group.memberAccessLevel < 4
                  ? `Click  here to share a resume with this group. This will give all members of the group access to that resume`
                  : `Click here to share a resume with this group. This will give all members of the group access to that resume after it's been approved by a group admin`
              }
            />
          ) : (
            ""
          )}
          {inviteToGroupButton}
          {joinGroupButton}
          {group.memberAccessLevel === 1 ? (
            <div
              onClick={() =>
                this.context.router.history.push(
                  "/groups/editGroup/" + group.id,
                )
              }
              className="panel-action"
            >
              <Popup
                trigger={<FontAwesome name="pencil" />}
                content="Edit group information"
              />
            </div>
          ) : (
            ""
          )}
          {group.approved ? (
            group.flagged ? (
              <div
                onClick={() => this.removeReportGroupAlert(group.id)}
                className="panel-action"
              >
                <Popup
                  trigger={
                    <FontAwesome
                      name="flag"
                      className="panel-action-flagged-active"
                    />
                  }
                  content={`'${group.name}' has been reported`}
                />
              </div>
            ) : (
              <div onClick={this.reportGroupModal} className="panel-action">
                <Popup
                  trigger={<FontAwesome name="flag" />}
                  content={`Report '${group.name}' group for inappropriate usage `}
                />
              </div>
            )
          ) : null}
        </div>
      </div>
    );
  }
}

GroupHeader.propTypes = {
  group: PropTypes.object.isRequired,
  hasVerifiedResume: PropTypes.number,
  addAlert: PropTypes.func.isRequired,
  clearAlert: PropTypes.func.isRequired,
  displayMobile: PropTypes.bool,
  switchMobileTab: PropTypes.func.isRequired,
  actions: PropTypes.shape({
    flagGroup: PropTypes.func.isRequired,
    unflagGroup: PropTypes.func.isRequired,
  }),
  groupMemberActions: PropTypes.shape({
    leaveMemberFromGroup: PropTypes.func.isRequired,
    requestToJoinGroup: PropTypes.func.isRequired,
  }),
  groupResumeActions: PropTypes.shape({
    addMyResumeToGroup: PropTypes.func.isRequired,
  }),
};

function mapStateToProps(state) {
  return {
    group: state.singleGroup.group,
    hasVerifiedResume: state.user.user.hasVerifiedResume,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(groupPageActions, dispatch),
    groupMemberActions: bindActionCreators(groupMemberActions, dispatch),
    groupResumeActions: bindActionCreators(groupResumeActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupHeader);

import React from "react";
import PropTypes from "prop-types";

const actionBarHeight = 63;

export default class resizeContainer extends React.Component {
  static childContextTypes = {
    objectHeights: PropTypes.object.isRequired,
    recalculateObjectHeights: PropTypes.func.isRequired,
  };

  getChildContext() {
    return {
      objectHeights: { ...this.state },
      recalculateObjectHeights: this.calculateHeights,
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      sidebarHeight: 400, //random number
      headerHeight: 60,
      searchBarHeight: 69,
      filterBarHeight: 29,
      resultCountHeight: 34,
      sidebarResultsHeight: 0,
    };
    this.calculateHeights = this.calculateHeights.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.calculateHeights);
    this.calculateHeights();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.calculateHeights);
  }

  calculateHeights = () => {
    const sidebarHeight = window.innerHeight;
    const header = document.getElementById("header");
    const searchBar = document.getElementById("search-filter");
    const filterBar = document.getElementById("filter-bar");
    const resultCount = document.getElementById("sidebar-list-count");
    const sidebarResults = document.getElementById("sidebar-list-results");

    const currentHeights = { ...this.state };
    const updatedHeights = {
      sidebarHeight,
      headerHeight: header ? header.clientHeight : currentHeights.headerHeight,
      searchBarHeight: searchBar
        ? searchBar.clientHeight
        : currentHeights.searchBarHeight,
      filterBarHeight: filterBar
        ? filterBar.clientHeight
        : currentHeights.filterBarHeight,
      resultCountHeight: resultCount
        ? resultCount.clientHeight
        : currentHeights.resultCountHeight,
      sidebarResultsHeight: sidebarResults
        ? sidebarResults.clientHeight
        : currentHeights.sidebarResultsHeight,
    };

    this.setState({
      ...updatedHeights,
      sidebarCalculatedHeight:
        updatedHeights.sidebarHeight -
        updatedHeights.headerHeight -
        (updatedHeights.searchBarHeight || 40) -
        (updatedHeights.filterBarHeight || 29) -
        (updatedHeights.resultCountHeight || 34) -
        updatedHeights.sidebarResultsHeight +
        5,
      mainPanelCalculatedHeight:
        updatedHeights.sidebarHeight -
        updatedHeights.headerHeight -
        actionBarHeight,
    });
  };

  render() {
    return <div style={{ height: "100%" }}>{this.props.children}</div>;
  }
}

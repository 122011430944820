import * as types from "../actionTypes/userActionTypes";

export function saveShulId(shulId) {
  return async (dispatch) => {
    dispatch({
      type: types.SAVE_SHUL_ID,
      shul_group_id: shulId,
    });
  };
}

import React from "react";
import PropTypes from "prop-types";
import InboxLeftPanel from "./inboxLeftPanel";
import InboxMainPanel from "./inboxMainPanel";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from "react-helmet";
import * as singleConversationActions from "../../actions/singleConversationActions";
import * as conversationPageActions from "../../actions/inboxPageActions";

class ConversationDisplay extends React.Component {
  static contextTypes = {
    router: PropTypes.object,
    showWelcome: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedConversation: props.match.params.id || 0,
      mobileTab: props.match.params.id ? "single" : "list",
    };
  }
  componentDidMount() {
    let props = this.props;

    if (props.conversation && props.conversation.id && !props.match.params.id) {
      // there is a conversation in state already but no id in the url
      this.context.router.history.push("/inbox/" + props.conversation.id);
    } else if (
      props.match.params.id &&
      (!props.conversation ||
        (props.conversation.id &&
          parseInt(props.match.params.id) !== props.conversation.id))
    ) {
      //if there is no conversation in state or the id in the url is different
      props.singleActions.getConversationById(props.match.params.id);
    }
    this.context.showWelcome("Inbox");
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.match.params.id) {
      this.setState({ selectedConversation: null });
      return;
    }
    if (nextProps.match.params.id !== this.props.match.params.id) {
      this.setState({ selectedConversation: nextProps.match.params.id }, () => {
        this.props.singleActions.getConversationById(nextProps.match.params.id);
      });
    }
  }

  selectConversation(id) {
    this.context.router.history.push(`/inbox/${id}`);
    this.setState({ mobileTab: "single" });
  }

  render() {
    let unreadConversationsCount = this.props.unreadConversations.length;
    let title = `Inbox ${
      unreadConversationsCount > 0 ? `[${unreadConversationsCount}]` : ""
    } | ZivugTech`;
    return (
      <div className="main-container mui-container-fluid">
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <InboxLeftPanel
          conversations={this.props.conversations}
          pagination={this.props.pagination}
          loadConversations={this.props.inboxActions.loadConversations}
          isLoading={this.props.isLoading}
          search={this.props.search}
          handleClick={this.selectConversation.bind(this)}
          selectedConversation={this.state.selectedConversation}
          displayMobile={this.state.mobileTab === "list"}
          switchMobileTab={() => this.setState({ mobileTab: "single" })}
        />
        <InboxMainPanel
          selectedConversation={this.state.selectedConversation}
          displayMobile={this.state.mobileTab === "single"}
          switchMobileTab={() => this.setState({ mobileTab: "list" })}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    conversations: state.conversations.conversations,
    unreadConversations: state.conversations.unreadConversations,
    search: state.conversations.search,
    conversation: state.singleConversation.conversation,
    isLoading: state.conversations.isLoading,
    pagination: state.conversations.pagination,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    singleActions: bindActionCreators(singleConversationActions, dispatch),
    inboxActions: bindActionCreators(conversationPageActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConversationDisplay);

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import * as userActions from "../../../actions/userActions";
import yup from "yup";
import Form from "react-formal";
// import FontAwesome from "react-fontawesome";
import { Helmet } from "react-helmet";
import { getNextUrlFromParams } from "../../common/utils";

const loginSchema = yup.object().shape({
  email: yup
    .string()
    .transform((string) => string.trim())
    .email("Invalid email")
    .required("Required"),
  password: yup.string().required("Required"),
});

class LoginPage extends React.Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      login: {},
      submitted: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentWillMount() {
    this.checkAuth(this.props);
  }

  checkAuth(props) {
    if (props.user.isAuthenticated) {
      this.setState({ submitted: true });
      let nextPath = getNextUrlFromParams(props.location.search);
      this.context.router.history.push(nextPath ? "/" + nextPath : "/resumes");
    }
  }

  handleChange(model) {
    this.setState({ login: model, submitted: false });
  }

  submit(values) {
    this.setState({ submitted: true });
    let nextPath = getNextUrlFromParams(this.props.location.search);
    let nextRoute = nextPath ? "/" + nextPath : "/resumes";
    this.props.actions.loginUser(values, nextRoute);
  }

  resendConfirmationEmail() {
    this.setState({ resentConfirmationEmail: true });
    this.props.actions.resendConfirmationEmail(
      this.props.user.unconfirmedUserId,
    );
  }

  render() {
    let {
      isLoggingIn,
      errorMessage,
      unconfirmedUserId,
      confirmationResent,
      userConfirmed,
    } = this.props.user;
    return (
      <div className="page-account page-login" style={{ paddingTop: 0 }}>
        <Helmet>
          <title>Login | ZivugTech</title>
        </Helmet>
        <div className="page-account-container">
          <Link to={"/login"}>
            <img
              src="/images/shulspace-shidduchim-white-logo.png"
              alt=""
              className="page-account-logo"
              style={{ width: 320 }}
            />
          </Link>

          {userConfirmed ? (
            <div className="account-message">User confirmed successfully</div>
          ) : null}

          <Form
            onSubmit={this.submit}
            id="form"
            schema={loginSchema}
            value={this.state.login}
            onChange={this.handleChange}
            className="page-account-content mui--z1"
          >
            <h3>Login</h3>

            <legend>
              Email
              <Form.Message className="has-error" for="email" />
            </legend>
            <Form.Field
              placeholder="Email"
              name="email"
              className="form-input-margin-bottom"
            />
            <legend>
              Password
              <Form.Message className="has-error" for="password" />
            </legend>
            <Form.Field
              placeholder="Password"
              type="password"
              name="password"
              className="form-input-margin-bottom"
            />
            <p className="page-account-text-link-under-input text-align-right">
              <Link to={`forgotPassword`}>Forgot Password?</Link>
            </p>

            {isLoggingIn ? (
              <div className="btn-flat btn-primaryAccent text-align-center btn-loader">
                <div className="loader-small"></div>
              </div>
            ) : (
              <Form.Button
                type="submit"
                className="btn-flat btn-primaryAccent btn-loader text-align-center"
              >
                Login
              </Form.Button>
            )}
            {errorMessage && this.state.submitted && (
              <p className="form-message-bottom text-align-center">
                {errorMessage}
                {unconfirmedUserId && !confirmationResent ? (
                  <span
                    onClick={this.resendConfirmationEmail.bind(this)}
                    className="form-message-bottom-resend"
                  >
                    Click here to resend the email
                  </span>
                ) : null}
              </p>
            )}
            {this.state.resentConfirmationEmail && confirmationResent && (
              <p className="form-message-bottom text-align-center">
                Confirmation resent. Please check your email
              </p>
            )}
          </Form>

          <p className="page-account-text-link page-account-text-link-light text-align-center">
            <Link to={`register`}>Not a user yet? Register Here</Link>
          </p>

          {/* <div className="powered-by">
            <Link to={"http://bitbean.com"} target="_blank">
              <span>
                Built with <FontAwesome name="heart" /> by{" "}
              </span>{" "}
              bitbean.com
            </Link>
          </div> */}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(userActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);

import * as types from "../actionTypes/resumeActionTypes";
import * as fromConversationTypes from "../actionTypes/sidebarResumeActionTypes";
import * as tagTypes from "../actionTypes/tagsActionTypes";
import history from "../history";
import { apiAuthRequest } from "./helpers";
import { toastr } from "react-redux-toastr";

export function loadSingleResume(id) {
  return async (dispatch, getState) => {
    dispatch({ type: types.LOAD_SINGLE_RESUME_START });
    const { user: { user: { group_id } = {} } = {} } = getState();

    let result = await apiAuthRequest(
      "get",
      `/resumes/getSingleResume?shul_group_id=${group_id}`,
      {
        id,
      },
    );
    if (result.success) {
      if (result.data.resumeMerged) {
        history.push(`/resumes/${result.data.newResumeId}`);
      } else {
        dispatch({
          type: types.LOAD_SINGLE_RESUME_SUCCESS,
          resume: result.data,
        });
      }
    } else {
      console.error(result.data);
      toastr.error("Error loading resume");
    }
  };
}

export function loadSingleSidebarResume(id) {
  return async (dispatch, getState) => {
    dispatch({ type: fromConversationTypes.LOAD_SINGLE_SIDEBAR_RESUME_START });
    const { user: { user: { group_id } = {} } = {} } = getState();
    let result = await apiAuthRequest(
      "get",
      `/resumes/getSingleResume?shul_group_id=${group_id}`,
      {
        id,
      },
    );
    if (result.success) {
      if (result.data.resumeMerged) {
        result = await apiAuthRequest(
          "get",
          `/resumes/getSingleResume?shul_group_id=${group_id}`,
          {
            id: result.data.newResumeId,
          },
        );
      }
      dispatch({
        type: fromConversationTypes.LOAD_SINGLE_SIDEBAR_RESUME_SUCCESS,
        resume: result.data,
      });
    } else {
      console.error(result.data);
      toastr.error("Error loading resume");
    }
  };
}

export function pinResume(resumeId) {
  return async (dispatch) => {
    let result = await apiAuthRequest("post", "/actions/pinResume", {
      resumeId,
    });
    if (result.success) {
      dispatch({ type: types.PIN_RESUME, resumeId });
    } else {
      toastr.error("Error pinning resume");
      console.error(result.data);
    }
  };
}

export function unpinResume(resumeId) {
  return async (dispatch) => {
    let result = await apiAuthRequest("post", "/actions/unpinResume", {
      resumeId,
    });
    if (result.success) {
      dispatch({ type: types.UNPIN_RESUME, resumeId });
    } else {
      toastr.error("Error unpinning resume");
      console.error(result.data);
    }
  };
}

function addTagToResumeSuccess(tag) {
  return {
    type: types.ADD_TAG_TO_RESUME,
    tag,
  };
}

export function addTagToResume(resumeTag, tagToAdd) {
  //tagId, resumeId
  return async (dispatch) => {
    let result = await apiAuthRequest(
      "post",
      "/userTags/addToResume",
      resumeTag,
    );
    if (result.success) {
      dispatch(addTagToResumeSuccess(tagToAdd));
    } else {
      toastr.error("Error adding tag");
      console.error(result.data);
    }
  };
}

export function removeTagFromResume(resumeTag) {
  //tagId, resumeId
  return async (dispatch) => {
    let result = await apiAuthRequest(
      "post",
      "/userTags/removeFromResume",
      resumeTag,
    );
    if (result.success) {
      dispatch({
        type: types.DELETE_TAG_FROM_RESUME,
        id: resumeTag.tagId,
      });
    } else {
      toastr.error("Error removing tag");
      console.error(result.data);
    }
  };
}

export function addNewTagToResume(resumeTag) {
  //tagText, resumeId
  return async (dispatch) => {
    let result = await apiAuthRequest(
      "post",
      "/userTags/createAndAddToResume",
      resumeTag,
    );
    if (result.success) {
      dispatch({
        type: tagTypes.CREATE_TAG_SUCCESS,
        tag: result.data,
      });
      dispatch(addTagToResumeSuccess(result.data));
    } else {
      toastr.error("Error adding tag");
      console.error(result.data);
    }
  };
}

export function addResumeNote(note) {
  return async (dispatch) => {
    dispatch({ type: types.SAVING_NOTE });
    let result = await apiAuthRequest("post", "/actions/createNote", note);
    if (result.success) {
      dispatch({
        type: types.ADD_NOTE_TO_RESUME,
        note: result.data,
      });
    } else {
      console.error(result.data);
      toastr.error("Error saving note");
    }
  };
}

export function editResumeNote(note) {
  return async (dispatch) => {
    dispatch({ type: types.SAVING_NOTE });

    let result = await apiAuthRequest("post", "/actions/editNote", note);
    if (result.success) {
      setTimeout(function () {
        //delay save completion so "saving" displays for longer
        dispatch({
          type: types.EDIT_NOTE_SUCCESS,
          note: result.data,
        });
      }, 1500);
    } else {
      console.error(result.data);
      toastr.error("Error saving note");
    }
  };
}

export function flagResume(flag, closeModal) {
  return async (dispatch) => {
    let result = await apiAuthRequest("post", "/actions/flagResume", {
      flaggedResumeId: flag.flaggedId,
      reason: flag.message,
    });
    if (result.success) {
      dispatch({ type: types.FLAG_RESUME, resumeId: flag.flaggedId });
    } else {
      toastr.error("Error reporting resume");
      console.error(result.data);
    }
    setTimeout(closeModal, 3000);
  };
}

export function unflagResume(resumeId) {
  return async (dispatch) => {
    let result = await apiAuthRequest("post", "/actions/unflagResume", {
      flaggedResumeId: resumeId,
    });
    if (result.success) {
      dispatch({
        type: types.UNFLAG_RESUME,
        resumeId,
      });
    } else {
      toastr.error("Error unflagging resume");
      console.error(result.data);
    }
  };
}

export function engageResume(engagement, closeModal) {
  return async (dispatch) => {
    let result = await apiAuthRequest(
      "post",
      "/engagements/reportEngagement",
      engagement,
    );
    if (result.success) {
      dispatch({
        type: types.ENGAGED_RESUME_REPORTED,
        resumeId: engagement.engagedResumeId,
      });
      setTimeout(closeModal, 3000);
    } else {
      console.error(result.data);
      toastr.error("Error marking resume as engaged");
    }
  };
}

export function editGroupResumeNote(note) {
  return async (dispatch) => {
    dispatch({
      type: types.SAVING_GROUP_RESUME_NOTE,
      groupResumeId: note.groupResumeId,
    });
    let result = await apiAuthRequest("post", "/actions/editGroupNote", note);
    if (result.success) {
      dispatch({
        type: types.EDIT_GROUP_RESUME_NOTE_SUCCESS,
        note: { ...result.data, groupResumeId: note.groupResumeId },
      });
    } else {
      console.error(result.data);
      toastr.error("Error saving note");
    }
  };
}

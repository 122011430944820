import * as types from "../actionTypes/constantsTypes";
import { apiAuthRequest } from "./helpers";
import { toastr } from "react-redux-toastr";

export function loadConsts() {
  return async (dispatch) => {
    let result = await apiAuthRequest("get", "/constants/getAll");
    if (result.success) {
      const filteredTypes = result.data.types?.filter(
        (t) =>
          t.text !== "Modern Yeshivish" &&
          t.text !== "Chabad" &&
          t.text !== "Modern Orthodox",
      );
      dispatch({
        type: types.LOAD_CONSTS_SUCCESS,
        consts: { ...result.data, types: filteredTypes },
      });
    } else {
      console.error(result.data);
      toastr.error("Something went wrong, please try refreshing the page");
    }
  };
}

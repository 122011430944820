import * as types from "../actionTypes/conversationActionTypes";
import * as userTypes from "../actionTypes/userActionTypes";

export default function singleConversationReducer(state = {}, action) {
  switch (action.type) {
    case types.CLEAR_SINGLE_CONVERSATION:
      return {
        ...state,
        conversation: {},
      };
    case types.LOAD_CONVERSATION_SUCCESS:
      return {
        conversation: { ...action.conversation },
        isLoading: false,
      };
    case types.LOAD_CONVERSATION_START:
      return {
        conversation: state.conversation,
        isLoading: true,
      };
    case types.SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        conversation: {
          ...state.conversation,
          messages: [...state.conversation.messages, action.message],
          read: true,
          lastViewedByMe: new Date(),
        },
      };
    case types.NEW_MESSAGE_RECEIVED:
      let isCurrentConversation =
        state.conversation &&
        state.conversation.id === parseInt(action.data.conversationId);
      return {
        ...state,
        conversation: {
          ...state.conversation,
          messages: isCurrentConversation
            ? [...state.conversation.messages, action.data]
            : state.conversation
            ? state.conversation.messages
            : null,
          lastActive: new Date(),
          lastMessageSentByMe: false,
          read: false,
        },
      };
    case types.MARK_CONVERSATION_UNREAD:
      return {
        ...state,
        conversation: { ...state.conversation, read: false },
      };
    case types.MARK_CONVERSATION_READ:
      return {
        ...state,
        conversation: {
          ...state.conversation,
          read: true,
          lastViewedByMe: new Date(),
        },
      };
    case types.ARCHIVE_CONVERSATION:
      return {
        ...state,
        conversation: { ...state.conversation, archived: true },
      };
    case types.UNARCHIVE_CONVERSATION:
      return {
        ...state,
        conversation: { ...state.conversation, archived: false },
      };
    case userTypes.FLAG_USER:
      return {
        ...state,
        conversation: {
          ...state.conversation,
          participantUser:
            state.conversation.participantUser.id === action.userId
              ? { ...state.conversation.participantUser, flagged: true }
              : state.conversation.participantUser,
        },
      };
    case userTypes.UNFLAG_USER:
      return {
        ...state,
        conversation: {
          ...state.conversation,
          participantUser:
            state.conversation.participantUser.id === action.userId
              ? { ...state.conversation.participantUser, flagged: false }
              : state.conversation.participantUser,
        },
      };
    default:
      return state;
  }
}

import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import LoginPage from "./components/account/login/LoginPage";
import NewPassword from "./components/account/login/newPassword";
import ForgotPassword from "./components/account/login/forgotPasswordPage";
// import ConfirmUser from "./components/account/register/confirmUser";
import ConfirmEmail from "./components/account/accountSettings/confirmEmail";
import RegisterPage from "./components/account/register/registerPage";
import ResumeDisplay from "./components/resumes/resumePage";
import ConversationDisplay from "./components/inbox/inboxPage";
import GroupsContainer from "./components/groups/GroupPage";
// import GroupCreateEditGroup from "./components/groups/GroupCreateEditGroup";
import { requireAuthentication } from "./components/common/AuthenticatedComponent";
// import CreateOptionsPage from "./components/resumes/create/createOptionsPage";
import UploadResumePdf from "./components/resumes/create/uploadResumePdf";
import Edit from "./components/resumes/create/editResume";
import IdeasPage from "./components/ideas/ideasPage";
import NotFoundPage from "./components/pages/NotFoundPage";
import CreateResumeNew from "./components/resumes/create/createNewResume";
import AccountSettings from "./components/account/accountSettings/accountSettingsPage";
// import WelcomePage from "./components/pages/WelcomePage";
import TermsPage from "./components/pages/TermsPage";
import LoadingPage from "./components/pages/loadingPage";

export default (
  <div style={{ height: "100%" }}>
    <Switch>
      <Route exact path="/loading" component={LoadingPage} />
      <Route exact path="/login" component={LoginPage} />
      <Route exact path="/register" component={RegisterPage} />
      <Route exact path="/forgotPassword" component={ForgotPassword} />
      {/* <Route exact path="/confirmUser/:token/:userId" component={ConfirmUser} /> */}
      <Route
        exact
        path="/confirmEmail/:token/:emailId"
        component={ConfirmEmail}
      />
      <Route exact path="/resetPassword/:token" component={NewPassword} />
      <Route exact path="/terms" component={TermsPage} />

      <Route
        exact
        path="/accountSettings"
        component={requireAuthentication(AccountSettings)}
      />

      <Route
        exact
        path="/myResume"
        component={requireAuthentication(CreateResumeNew)}
      />

      <Route
        exact
        path="/resumes/edit"
        component={requireAuthentication(Edit)}
      />
      {/*<Route path='/resumes/create' component={CreateOptionsPage} />*/}
      <Route
        exact
        path="/resumes/create"
        component={requireAuthentication(CreateResumeNew)}
      />
      <Route
        exact
        path="/resumes/create/resumeNew"
        component={requireAuthentication(CreateResumeNew)}
      />
      <Route
        exact
        path="/resumes/create/uploadPdf"
        component={requireAuthentication(UploadResumePdf)}
      />
      <Route
        exact
        path="/resumes/edit/:id"
        component={requireAuthentication(CreateResumeNew)}
      />
      <Route
        exact
        path="/resumes/:id?"
        component={requireAuthentication(ResumeDisplay)}
      />

      <Route
        exact
        path="/inbox/:id?"
        component={requireAuthentication(ConversationDisplay)}
      />

      {/* <Route
        exact
        path="/groups/createNewGroup"
        component={requireAuthentication(GroupCreateEditGroup)}
      /> 
      <Route
        exact
        path="/groups/editGroup/:id"
        component={requireAuthentication(GroupCreateEditGroup)}
      /> */}
      <Route
        exact
        path="/groups/:id"
        component={requireAuthentication(GroupsContainer)}
      />

      <Route exact path="/ideas" component={requireAuthentication(IdeasPage)} />

      {/* <Route
        exact
        path="/welcome"
        component={requireAuthentication(WelcomePage)}
      /> */}

      <Route exact path="/" component={LoadingPage} />

      <Route exact path="/404" component={NotFoundPage} />
      <Redirect path="*" to="/404" />
    </Switch>
  </div>
);

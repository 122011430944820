import React from "react";
import PropTypes from "prop-types";
import Infinite from "../common/Infinite";
import moment from "moment-timezone";
import Popup from "../common/Popup";
import FontAwesome from "react-fontawesome";
import { cutString } from "../common/utils";
import _ from "lodash";

const emptySearch = {
  pageNumber: 1,
  filterBy: "",
  searchText: "",
  unreadOnly: false,
};

export default class InboxLeftPanel extends React.Component {
  static contextTypes = {
    objectHeights: PropTypes.object.isRequired,
    recalculateObjectHeights: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      searchActive: false,
      search: {
        ...emptySearch,
        pageNumber: props.pagination.page,
      },
    };
    this.timezone = moment.tz.guess();
    this.reload = this.reload.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.loadMoreConversations = this.loadMoreConversations.bind(this);
    this.filterDropDown = this.filterDropDown.bind(this);
    this.unreadChanged = this.unreadChanged.bind(this);
    this.inputChanged = this.inputChanged.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount() {
    if (this.props.search.pageNumber) {
      let previousSearch = { ...this.state.search, ...this.props.search };
      this.setState({ search: previousSearch }, () => {
        this.reload();
      });
    } else if (this.props.selectedConversation) {
      this.setState(
        { search: { ...this.state.search, filterBy: "all" } },
        () => {
          this.reload();
        },
      );
    } else {
      this.reload(); // always reload to check for new messages
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.displayMobile !== this.props.displayMobile) {
      nextContext.recalculateObjectHeights();
    }
  }

  filterDropDown(event) {
    let search = { ...this.state.search };
    search.filterBy = event.target.value;
    this.setState({ search }, () => {
      this.reload();
    });
  }

  unreadChanged() {
    let search = { ...this.state.search };
    search.pageNumber = 1;
    search.unreadOnly = !search.unreadOnly;
    this.setState({ search }, () => {
      this.reload();
    });
  }

  reload() {
    let search = { ...this.state.search };
    search.pageNumber = 1;
    let searchActive = !_.isEqual(search, emptySearch);
    this.setState({ search, searchActive }, () => {
      this.props.loadConversations(search);
    });
  }

  inputChanged(event) {
    const field = event.target.name;
    let search = { ...this.state.search };
    search[field] = event.target.value;
    return this.setState({ search });
  }

  handleKeyPress(e) {
    if (e.key === "Enter") {
      this.reload();
    }
  }

  clearSearch() {
    let search = { ...emptySearch };
    this.setState({ search }, () => {
      this.reload();
    });
  }

  loadMoreConversations() {
    if (this.state.search.pageNumber > this.props.pagination.pageCount + 1) {
      return;
    }
    if (this.state.search.pageNumber >= this.props.pagination.page + 2) {
      //don't load too many pages ahead
      return;
    }
    let search = { ...this.state.search };
    search.pageNumber = search.pageNumber + 1;
    this.setState({ search }, () => {
      this.props.loadConversations(search);
    });
  }

  inboxRow(conversation) {
    let date = moment.tz(conversation.lastActive, this.timezone).fromNow();
    let initials = conversation.participantName
      ? conversation.participantName.charAt(0)
      : "?";
    let selectedClass =
      parseInt(this.props.selectedConversation) === conversation.id
        ? "sidebar-list-item sidebar-list-item-active"
        : "sidebar-list-item";
    return (
      <li
        key={conversation.id}
        onClick={this.props.handleClick.bind(null, conversation.id)}
        className={selectedClass}
      >
        <span
          className={
            conversation.read
              ? "sidebar-list-item-read sidebar-column-small"
              : "sidebar-list-item-unread sidebar-column-small"
          }
        >
          <FontAwesome name="circle" />
        </span>
        <div className="sidebar-img-container">
          <div className="sidebar-list-item-image">
            <span>{initials}</span>
          </div>
        </div>
        <div className="sidebar-list-item-info sidebar-column-large">
          <h5
            className={
              !conversation.read
                ? "sidebar-list-item-unread-title sidebar-list-item-title"
                : "sidebar-list-item-title"
            }
          >
            {conversation.participantName}
          </h5>
          <p className="sidebar-list-item-info-details">
            <span>{cutString(conversation.subject, 18)}</span>
          </p>
          <p className="sidebar-list-item-info-types">
            <span>
              {conversation.lastMessageSentByMe ? "sent" : "received"} {date}
            </span>
          </p>
        </div>
        <div className="sidebar-list-item-extra sidebar-column-medium">
          <span>{conversation.suggestionType}</span>
        </div>
      </li>
    );
  }

  render() {
    let conversationCount = this.props.pagination.rowCount;
    return (
      <div
        id="sidebar-left"
        className={`header-padding ${
          this.props.displayMobile ? "" : "mobile-hide"
        }`}
      >
        <div className="search-filter" id="search-filter">
          <div className="search-bar">
            <div className="search">
              <input
                className="input"
                type="text"
                onChange={this.inputChanged}
                onBlur={this.reload}
                onKeyPress={this.handleKeyPress}
                value={this.state.search.searchText}
                placeholder="Search Messages"
                autoComplete="off"
                name="searchText"
              />
            </div>
          </div>
        </div>
        <div id="filter-bar" className="filter-bar">
          <div className="sidebar-column-small filter-column-read">
            {this.state.search.filterBy ? null : (
              <Popup
                trigger={
                  this.state.search.unreadOnly ? (
                    <FontAwesome
                      name="circle"
                      onClick={this.unreadChanged}
                      className="active"
                    />
                  ) : (
                    <FontAwesome name="circle-o" onClick={this.unreadChanged} />
                  )
                }
                content="Filter by new messages"
              />
            )}
          </div>
          <div className="sidebar-column-large filter-column-dropdown">
            <p>Include:</p>
            <select
              onChange={this.filterDropDown}
              value={this.state.search.filterBy}
              className="inbox-settings-select mui-select"
            >
              <option className="option" value="all" label="All" />
              <option className="option" value="" label="Received" />
              <option className="option" value="sent" label="Sent" />
              <option className="option" value="archived" label="Archived" />
              <option className="option" value="shared" label="Shared" />
              <option
                className="option"
                value="suggestions"
                label="Suggestions"
              />
              <option className="option" value="ideas" label="Ideas" />
            </select>
          </div>
        </div>
        {this.state.searchActive ? (
          <div className="sidebar-list-results" id="sidebar-list-results">
            <p>
              <span className="sidebar-list-results-bold">
                {conversationCount}{" "}
              </span>
              Result{conversationCount === 1 ? "" : "s"}
            </p>
            <p onClick={this.clearSearch} className="" type="button">
              <span>Clear</span>
            </p>
          </div>
        ) : (
          ""
        )}
        <div className="sidebar-list" id="resumeList">
          <ul>
            <Infinite
              containerHeight={
                this.context.objectHeights.sidebarCalculatedHeight
              }
              hasMore={conversationCount > this.props.conversations.length}
              className="infinite"
              loadingMore={!!this.props.isLoading}
              itemList={this.props.conversations}
              renderRow={this.inboxRow.bind(this)}
              noItemsText="No messages."
              loadMore={this.loadMoreConversations.bind(this)}
            />
          </ul>
        </div>
        <div className="sidebar-list-count" id="sidebar-list-count">
          <p>
            {conversationCount} Conversations{conversationCount > 1 ? "s" : ""}
          </p>
        </div>
      </div>
    );
  }
}

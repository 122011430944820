import * as types from "../actionTypes/userActionTypes";
import history from "../history";
import { loadConsts } from "./constsActions";
import { loadNotifications } from "./notificationActions";
import { loadTags } from "./userTagActions";
import { loadUnreadConversations } from "./inboxPageActions";
import { loadWelcome } from "./welcomeActions";
import cookie from "cookie";
import { apiRequest, apiAuthRequest } from "./helpers";
import { toastr } from "react-redux-toastr";

export function loginUserSuccess(user) {
  return {
    type: types.USER_LOGIN_SUCCESS,
    user,
    analytics: {
      event: "login",
      params: {},
      userId: user.id,
    },
  };
}

export function registerSuccess(unconfirmedUserId) {
  return {
    type: types.REGISTER_USER_SUCCESS,
    unconfirmedUserId,
    analytics: {
      event: "sign_up",
      params: {},
      userId: unconfirmedUserId,
    },
  };
}

export function userNotLoggedIn() {
  return {
    type: types.USER_NOT_LOGGED_IN,
    analytics: {
      event: "",
      params: {},
      userId: "",
    },
  };
}

export function confirmUserSuccess(userId) {
  return {
    type: types.CONFIRM_USER_SUCCESS,
    analytics: {
      event: "confirm_user",
      params: {},
      userId: userId,
    },
  };
}

export function loginUser(loginInfo, redirectUrl) {
  return async (dispatch) => {
    dispatch({ type: types.USER_LOGIN_START });

    let result = await apiRequest("post", "/users/login", {
      email: loginInfo.email,
      password: loginInfo.password,
    });
    if (result.success && result.data.success) {
      const groups = result.data.user.groups;
      let group_id;
      if (Array.isArray(groups) && groups.length) {
        group_id = groups[0].id;
      }
      updateCookie(result.data.token, 2);
      dispatch(loginUserSuccess({ ...result.data.user, group_id }));
      getUserData(dispatch, result.data.token);
      if (result.data.user.loginCount < 5 && redirectUrl === "/resumes") {
        history.push("/resumes");
      } else {
        history.push(redirectUrl);
      }
    } else if (result.success) {
      dispatch({
        type: types.USER_LOGIN_ERROR,
        data: result.data,
      });
    } else {
      toastr.error(
        "Something went wrong while trying to log you in, please try again later or contact support",
      );
      console.error(result.data);
    }
  };
}

export function getLoggedInUser() {
  return async (dispatch) => {
    let token = cookie.parse(document.cookie).zivugtechToken;
    if (!token) {
      return dispatch(userNotLoggedIn());
    }

    let result = await apiAuthRequest("get", "/users/getCurrentUser");
    if (result.success) {
      const groups = result.data.groups;
      let group_id;
      if (Array.isArray(groups) && groups.length) {
        group_id = groups[0].id;
      }
      dispatch(loginUserSuccess({ ...result.data, group_id }));
      getUserData(dispatch, token);
      updateCookie(token, 2);
    } else {
      updateCookie("", -1);
      dispatch(userNotLoggedIn());
    }
  };
}

export function getUserData(dispatch, token) {
  dispatch(loadWelcome());
  dispatch(loadConsts());
  dispatch(loadUnreadConversations());
  dispatch(loadNotifications());
  dispatch(loadTags());
  // dispatch({type:'socket/connect', data: {token: token}});
}

export function registerUser(newUser, shul_group_id) {
  return async (dispatch) => {
    if (!shul_group_id) {
      console.error("Missing shul group id");
      dispatch({
        type: types.REGISTER_USER_ERROR,
        errorMessage: "Something went wrong, please try refreshing the page",
      });
      return;
    }
    dispatch({ type: types.REGISTER_USER_START });
    let result = await apiRequest("post", "/users/register", {
      ...newUser,
      shul_group_id,
    });
    if (result.success) {
      dispatch(registerSuccess(result.data.unconfirmedUserId));
      dispatch(
        loginUser(
          {
            email: newUser.email,
            password: newUser.password,
          },
          "/resumes",
        ),
      );
    } else {
      dispatch({
        type: types.REGISTER_USER_ERROR,
        errorMessage: result.data,
      });
    }
  };
}

export function clearRegisterPage() {
  return { type: types.REGISTER_USER_DONE };
}

// export function confirmUser(params) {
//   return async (dispatch) => {
//     let result = await apiRequest("post", "/users/confirmUser", params);
//     if (result.success && result.data.confirmed) {
//       dispatch(confirmUserSuccess(params.userId));
//       setTimeout(() => history.push("/login"), 1000);
//     } else {
//       dispatch({
//         type: types.CONFIRM_USER_FAIL,
//         data: result.data,
//       });
//     }
//   };
// }

export function resendConfirmationEmail(userId) {
  return async (dispatch) => {
    let result = await apiRequest("post", "/users/resendConfirmationEmail", {
      userId,
    });
    if (result.success) {
      dispatch({
        type: types.RESEND_CONFIRMATION_SUCCESS,
        data: result.data,
      });
    } else {
      dispatch({
        type: types.CONFIRM_USER_FAIL,
        data: result.data,
      });
    }
  };
}

export function updateUser(params) {
  return async (dispatch) => {
    let result = await apiAuthRequest("post", "/users/editUser", params);
    if (result.success) {
      dispatch({
        type: types.ACCOUNT_SETTINGS_SAVED,
        user: result.data,
      });
    } else {
      dispatch({
        type: types.ACCOUNT_SETTINGS_ERROR,
        errorMessage: result.data,
      });
    }
  };
}

export function updatePassword(params) {
  return async (dispatch) => {
    let result = await apiAuthRequest("post", "/users/changePassword", params);
    if (result.success) {
      dispatch({ type: types.SECURITY_SETTINGS_SAVED });
    } else {
      dispatch({
        type: types.SECURITY_SETTINGS_ERROR,
        errorMessage: result.data,
      });
    }
  };
}

export function addEmail(params) {
  //email, password
  return async (dispatch) => {
    let result = await apiAuthRequest("post", "/users/addEmail", params);
    if (result.success) {
      dispatch({
        type: types.ADD_USER_EMAIL_SUCCESS,
        userEmail: result.data,
      });
    } else {
      dispatch({
        type: types.ADD_USER_EMAIL_FAIL,
        errorMessage: result.data,
      });
    }
  };
}

export function removeEmail(userEmailId) {
  return async (dispatch) => {
    let result = await apiAuthRequest("post", "/users/removeEmail", {
      id: userEmailId,
    });
    if (result.success) {
      dispatch({
        type: types.REMOVE_USER_EMAIL_SUCCESS,
        userEmailId: userEmailId,
      });
    } else {
      dispatch({
        type: types.REMOVE_USER_EMAIL_FAIL,
        errorMessage: result.data,
      });
    }
  };
}

export function resendEmailVerification(userEmailId) {
  return async (dispatch) => {
    let result = await apiAuthRequest(
      "post",
      "/users/resendEmailVerification",
      { userEmailId },
    );
    if (result.success) {
      dispatch({
        type: types.RESEND_EMAIL_VERIFICATION_SUCCESS,
        userEmailId: userEmailId,
      });
    } else {
      dispatch({
        type: types.RESEND_EMAIL_VERIFICATION_FAIL,
        errorMessage: result.data,
      });
    }
  };
}

export function inviteUser(email) {
  return async (dispatch) => {
    dispatch({ type: types.INVITE_USER_START });
    let result = await apiAuthRequest("post", "/users/inviteUser", { email });
    if (result.success) {
      setTimeout(() => dispatch({ type: types.INVITE_USER_SUCCESS }), 3000);
    } else {
      toastr.error("Error inviting user");
      console.error(result.data);
    }
  };
}

export function verifyUser(image, closeModal) {
  return async (dispatch) => {
    if (image.name) {
      let fd = new FormData();
      fd.append("image", image, image.name);
      const imageUploadResult = await apiAuthRequest(
        "post",
        "/uploads/uploadUserVerificationImage",
        fd,
      );
      if (!imageUploadResult.success) {
        console.error(imageUploadResult.data);
        toastr.error("Error uploading image");
        return;
      }
      let imageLocation = imageUploadResult.data.fileLocation;

      let result = await apiAuthRequest("post", "/users/uploadIdImage", {
        imageLocation,
      });
      if (result.success) {
        dispatch({
          type: types.UPLOAD_USER_ID_SUCCESS,
          verificationImage: result.data,
        });
        setTimeout(closeModal, 3000);
      } else {
        console.error(result.data);
        toastr.error("Error uploading image");
      }
    }
  };
}

export function flagUser(flag, closeModal) {
  return async (dispatch) => {
    let result = await apiAuthRequest("post", "/users/flagUser", {
      flaggedUserId: flag.flaggedId,
      reason: flag.message,
    });
    if (result.success) {
      dispatch({
        type: types.FLAG_USER,
        userId: flag.flaggedId,
      });
    } else {
      toastr.error("Error reporting user");
    }
    setTimeout(closeModal, 3000);
  };
}

export function unflagUser(userId) {
  return async (dispatch) => {
    let result = await apiAuthRequest("post", "/users/unflagUser", {
      flaggedUserId: userId,
    });
    if (result.success) {
      dispatch({
        type: types.UNFLAG_USER,
        userId,
      });
    } else {
      toastr.error("Error unflagging user");
    }
  };
}

export function updateCookie(value, hours) {
  let expires = "";
  if (hours) {
    let date = new Date();
    date.setTime(date.getTime() + hours * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  if (window.location.protocol.includes("https")) {
    document.cookie =
      "zivugtechToken=" + value + expires + "; path=/; SameSite=None; Secure";
  } else {
    document.cookie =
      "zivugtechToken=" + value + expires + "; path=/ ;SameSite=Lax";
  }
}

export function logOut() {
  updateCookie("", -1);
  return {
    type: types.USER_LOGOUT,
    analytics: {
      event: "logout",
      params: {},
      userId: "",
    },
  };
}

import React from "react";
import { bindActionCreators } from "redux";
import Dropzone from "react-dropzone";
import axios from "axios";
import { getConfig } from "../../../actions/helpers";
import FontAwesome from "react-fontawesome";
import Subheader from "../../common/SubHeader";
import Iframe from "react-iframe";

class uploadResumePdf extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "",
    };
  }

  onDrop(acceptedFiles) {
    let image = acceptedFiles[0];
    let config = getConfig();
    if (config) {
      let inside = this;
      let fd = new FormData();
      fd.append("image", image, image.name);
      axios
        .post("/api/uploads/uploadResumePDF", fd, config)
        .then((imageUpload) => {
          if (imageUpload.data.fileLocation) {
            inside.setState({
              selected: imageUpload.data.fileLocation,
              pdfRejected: false,
            });
          } else {
            inside.setState({ pdfRejected: true });
          }
        });
    }
  }

  render() {
    return (
      <div className="container">
        <Subheader title="Upload PDF" />
        <div className="sub-header-content">
          <div className="list-items-container mui-row">
            <Dropzone
              onDropRejected={() => this.setState({ pdfRejected: true })}
              onDropAccepted={this.onDrop}
              disablePreview={true}
              accept="application/pdf"
              className="dragDrop"
              activeClassName="dragDropActive"
            >
              <div>
                <FontAwesome
                  name="upload"
                  className="group-color-light mui--text-center"
                />
                <h4 className="mui--text-center">
                  Drop a .pdf file here, or{" "}
                  <span className="dragDropFileBtn">select file to upload</span>
                </h4>
              </div>
              {this.state.pdfRejected ? (
                <span>Whoops... that file won't work. Try again!</span>
              ) : null}
            </Dropzone>
            <div className="mui-row">
              <div className="mui-col-md-6">
                <p>Resume</p>
              </div>
              <div className="mui-col-md-6">
                <div className="iframe-wrapper">
                  <div className="h_iframe">
                    <Iframe url={this.state.selected} width="100%" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default uploadResumePdf;

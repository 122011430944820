import AddResumeToGroupModal from "../groups/modal/GroupModalAddResume";
import EngageModal from "./engageResumeModal";
import FlagModal from "./flagModal";
import GroupModalInviteMember from "../groups/modal/GroupModalInviteMember";
import IdeaModal from "../ideas/ideaModal";
import ShareModal from "../resumes/share/shareResumeModal";
import VerifyUserModal from "../account/accountSettings/verifyUserModal";

export default {
  ADD_RESUME_TO_GROUP: AddResumeToGroupModal,
  ENGAGE: EngageModal,
  FLAG: FlagModal,
  GROUPS_INVITE_MEMBER: GroupModalInviteMember,
  IDEA: IdeaModal,
  SHARE: ShareModal,
  VERIFY_USER: VerifyUserModal,
};

import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import yup from "yup";
import Form from "react-formal";
import { Helmet } from "react-helmet";
import FontAwesome from "react-fontawesome";

const schema = yup.object().shape({
  email: yup.string().email("Invalid email").required("Required"),
});

export default class ForgotPassword extends React.Component {
  constructor() {
    super();
    this.state = {
      forgotPassword: {
        email: "",
      },
      submitting: false,
      success: false,
      error: false,
      message: "",
    };
    this.submit = this.submit.bind(this);
  }

  async submit(values) {
    this.setState({ submitting: true });

    let result = await axios.post("/api/users/forgotPassword", values);
    if (result.data.message) {
      this.setState({
        submitting: false,
        success: result.data.success,
        message: result.data.message,
      });
    } else {
      this.setState({
        submitting: false,
        success: false,
        message: "Looks like something went wrong. Please try again.",
      });
    }
  }

  render() {
    return (
      <div className="page-account page-login">
        <Helmet>
          <title>Forgot Password | ZivugTech</title>
        </Helmet>
        <div className="page-account-container">
          {/* <Link to={"/login"}>
            <img src="/images/logo.png" alt="" className="page-account-logo" />
          </Link> */}

          <Form
            id="form"
            className="page-account-content mui--z1"
            schema={schema}
            value={this.state.forgotPassword}
            onChange={(model) => this.setState({ forgotPassword: model })}
            onSubmit={this.submit}
          >
            <h3>Reset password</h3>
            <div>
              <legend>
                Enter Your Email
                <Form.Message className="has-error" for="email" />
              </legend>
              <Form.Field
                placeholder="ralph@zivugtech.org"
                name="email"
                className="form-input-margin-bottom"
              />
              {this.state.submitting ? (
                <p className="btn-flat btn-primaryAccent text-align-center btn-loader">
                  <span className="loader-small"></span>
                </p>
              ) : (
                <button
                  type="submit"
                  className="btn-flat btn-primaryAccent btn-loader text-align-center"
                >
                  {this.state.success
                    ? "Resend Password Reset Link"
                    : "Request Password Reset Link"}
                </button>
              )}
            </div>
            <p className="form-message-bottom text-align-center">
              {this.state.message}
            </p>
          </Form>

          <p className="page-account-text-link page-account-text-link-light text-align-center">
            <Link to={`login`}>Back to login</Link>
          </p>

          {/* <div className="powered-by">
            <Link to={"http://bitbean.com"} target="_blank">
              <span>
                Built with <FontAwesome name="heart" /> by{" "}
              </span>{" "}
              bitbean.com
            </Link>
          </div> */}
        </div>
      </div>
    );
  }
}

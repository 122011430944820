import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { parse } from "query-string";
import * as uiActions from "../../actions/uiActions";
import { getNextUrlFromParams } from "../common/utils";

class LoadingPage extends React.Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  state = {};

  componentDidMount() {
    this.checkAuth(this.props);
    const { shul_group_id } = parse(this.props.location.search);
    if (shul_group_id) {
      this.props.actions.saveShulId(shul_group_id);
    }
  }

  componentWillReceiveProps(nextProps) {
    this.checkAuth(nextProps);
  }

  checkAuth(props) {
    let nextRoute = getNextUrlFromParams(props.location.search);
    if (props.user.isAuthenticated) {
      this.context.router.history.push(
        nextRoute ? "/" + nextRoute : "/resumes",
      );
    } else if (!props.user.isLoading) {
      this.context.router.history.push(
        `/login${nextRoute ? `?next=${nextRoute}` : ""}`,
      );
    }
  }

  render() {
    return (
      <div className="page-account-container">
        <div className="loader"></div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(uiActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoadingPage);

import React from "react";
import PropTypes from "prop-types";
import FontAwesome from "react-fontawesome";
import { Scrollbars } from "react-custom-scrollbars";
import Popup from "../common/Popup";
import SingleResume from "../resumes/singleResume";

class InboxRightPanel extends React.Component {
  static contextTypes = {
    objectHeights: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showSidebar: true,
      resumeSelected: false,
    };
    this.selectResume = this.selectResume.bind(this);
  }

  toggleSidebar() {
    if (this.refs.sidebarContentResume) {
      this.props.toggleSidebar();
      //this.setState({showSidebar: !this.state.showSidebar});
    }
  }

  selectResume(id) {
    this.setState({ resumeSelected: true }, () => {
      this.props.loadSingleResume(id);
    });
  }

  render() {
    let renderThis;
    if (this.props.isLoading) {
      renderThis = (
        <div className="right-panel">
          <div className="loader"></div>
        </div>
      );
    } else if (this.props.resume && this.state.resumeSelected) {
      renderThis = (
        <div className="sidebar-buttons-padding">
          <div className="sidebar-buttons">
            <p
              className="btn-sidebar-create"
              onClick={() => this.setState({ resumeSelected: false })}
            >
              <FontAwesome name="arrow-left" /> Go Back
            </p>
          </div>
          <div className="inbox-resume-section">
            <SingleResume resume={this.props.resume} sidebarView={true} />
          </div>
        </div>
      );
    } else if (this.props.conversation) {
      renderThis = (
        <div className="container">
          {this.props.conversation.resumes.map((r) => {
            return (
              <div
                key={r.id}
                onClick={this.selectResume.bind(null, r.resumeId)}
                className="sidebar-list-item-info sidebar-list-item-info-inboxResume"
              >
                <i className="material-icons attached-resume">attach_file</i>
                <h5 className="sidebar-list-item-title">
                  {r.resume.firstName} {r.resume.lastName}
                </h5>
                <p className="sidebar-list-item-btn-view">
                  View <FontAwesome name="arrow-right" />
                </p>
              </div>
            );
          })}
          {this.props.conversation.nameShares.map((r) => {
            return (
              <Popup
                key={r.id}
                trigger={
                  <div className="sidebar-list-item-info sidebar-list-item-info-inboxResume sidebar-list-item-info-inboxResume-noAttachment">
                    <h5 className="sidebar-list-item-title">{r.name}</h5>
                  </div>
                }
                content="No attached resume. This was created using a name and not a resume."
              />
            );
          })}
        </div>
      );
    } else {
      renderThis = (
        <div className="message-sidebar-empty">
          <FontAwesome name="envelope" className="message-sidebar-empty-icon" />
          <p>No Resumes</p>
        </div>
      );
    }

    let openArrow;
    if (!this.props.showSidebar) {
      openArrow = (
        <span
          className="sidebar-content-arrow"
          onClick={this.toggleSidebar.bind(this)}
        >
          <FontAwesome name="arrow-left" className="open-sidebar" />
        </span>
      );
    } else {
      openArrow = (
        <span
          className="sidebar-content-arrow"
          onClick={this.toggleSidebar.bind(this)}
        >
          <FontAwesome name="arrow-right" className="close-sidebar" />
        </span>
      );
    }

    let sidebarClass = this.props.showSidebar
      ? "header-padding active"
      : "header-padding";

    return (
      <div
        id="sidebar-content"
        className={sidebarClass}
        ref="sidebarContentResume"
      >
        {openArrow}

        <div id="sidebar-content-content">
          <div
            className="panel-actions panel-action-one"
            ref="tabs"
            onClick={() => this.setState({ resumeSelected: false })}
          >
            <div className="panel-action active">
              <p>Resumes</p>
            </div>
          </div>
          <Scrollbars
            style={{
              width: 350,
              height: this.context.objectHeights.mainPanelCalculatedHeight,
            }}
          >
            <div className="content-padding">
              <div className="sidebar-tab active">
                <div className="sidebar-resume-shares">{renderThis}</div>
              </div>
            </div>
          </Scrollbars>
        </div>
      </div>
    );
  }
}

export default InboxRightPanel;

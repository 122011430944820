import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as groupPageActions from "../../actions/groupPageActions";
import * as groupMemberActions from "../../actions/groupMemberActions";
import GroupLeftPanel from "./GroupLeftPanel";
import GroupMain from "./main/GroupMain";
import { Helmet } from "react-helmet";

class GroupPage extends Component {
  static contextTypes = {
    router: PropTypes.object,
    showWelcome: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      selectedGroup: this.props.user.group_id,
      mobileTab: props.match.params.id ? "single" : "list",
    };
    this.selectGroup = this.selectGroup.bind(this);
    this.addAlert = this.addAlert.bind(this);
    this.clearAlert = this.clearAlert.bind(this);
  }

  componentDidMount() {
    this.props.actions.loadSingleGroup(this.props.user.group_id);
    // if (this.props.group.id && !this.props.match.params.id) {
    //   // there is a group in state already
    //   this.context.router.history.push("/groups/" + this.props.group.id);
    // } else if (
    //   (this.props.match.params.id &&
    //     this.props.match.params.id !== this.props.group.id) ||
    //   this.props.location.hash === "#updated"
    // ) {
    //   this.props.actions.loadSingleGroup(this.props.match.params.id);
    // }
    // this.context.showWelcome("Groups");
  }

  // componentWillReceiveProps(props) {
  //   if (!props.match.params.id) {
  //     this.setState({ selectedGroup: null });
  //     return;
  //   }
  //   if (props.match.params.id !== this.props.match.params.id) {
  //     this.setState({ selectedGroup: props.match.params.id }, () => {
  //       this.props.actions.loadSingleGroup(props.match.params.id);
  //     });
  //   }
  //   if (this.props.location.hash !== props.location.hash) {
  //     //if coming from a notification, refresh data
  //     if (props.match.params.id === this.props.match.params.id) {
  //       this.props.actions.loadSingleGroup(this.props.match.params.id);
  //     }
  //   }
  // }

  selectGroup(id) {
    this.context.router.history.push("/groups/" + id);
    this.setState({ mobileTab: "single" });
  }

  addAlert(alert) {
    this.setState({ alert });
  }

  clearAlert() {
    this.setState({ alert: null });
  }

  render() {
    let content = "";

    if (this.props.loadGroupError) {
      content = (
        <div id="content-main" className="header-padding column-layout-two">
          <div className="content-padding message-empty">
            <i className="material-icons">description</i>
            <p>
              Group does not exist or you do not have permission to access this
              group
            </p>
            <p>Please choose another group</p>
          </div>
        </div>
      );
    } else if (this.props.isLoadingGroup) {
      content = <div className="loader"></div>;
    } else if (this.props.group.id && this.state.selectedGroup) {
      content = (
        <div className="container">
          <GroupMain
            addAlert={this.addAlert}
            clearAlert={this.clearAlert}
            displayMobile={this.state.mobileTab === "single"}
            switchMobileTab={() => this.setState({ mobileTab: "list" })}
            acceptInvite={this.props.groupMemberActions.acceptGroupMemberInvite}
          />
        </div>
      );
    }
    // else {
    //   content = (
    //     <div id="content-main" className="header-padding column-layout-two">
    //       <div className="content-padding message-empty">
    //         <i className="material-icons">description</i>
    //         <p>No Group Selected</p>
    //         <p>
    //           Please choose a group from the left panel to view its content.
    //         </p>
    //       </div>
    //     </div>
    //   );
    // }

    return (
      <div
        className="main-container groupPage mui-container-fluid"
        ref="groupsContainer"
      >
        <Helmet>
          <title>Groups | ZivugTech</title>
        </Helmet>
        <GroupLeftPanel
          selectGroup={this.selectGroup}
          selectedGroup={this.state.selectedGroup}
          location={this.props.location}
          displayMobile={this.state.mobileTab === "list"}
          switchMobileTab={() => this.setState({ mobileTab: "single" })}
        />
        <div
          className={`${
            this.state.mobileTab === "single" ? "" : "mobile-hide"
          }`}
        >
          {content}
        </div>
        {this.state.alert}
      </div>
    );
  }
}

GroupPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }),
  location: PropTypes.object.isRequired,
  group: PropTypes.object.isRequired,
  isLoadingGroup: PropTypes.bool,
  loadGroupError: PropTypes.string,
  hasVerifiedResume: PropTypes.number,
  user: PropTypes.object.isRequired,
  actions: PropTypes.shape({
    loadSingleGroup: PropTypes.func.isRequired,
  }),
  groupMemberActions: PropTypes.shape({
    acceptGroupMemberInvite: PropTypes.func.isRequired,
  }),
};

function mapStateToProps(state) {
  return {
    group: state.singleGroup.group,
    isLoadingGroup: state.singleGroup.isLoadingGroup,
    loadGroupError: state.singleGroup.loadGroupError,
    hasVerifiedResume: state.user.user.hasVerifiedResume,
    user: state.user.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(groupPageActions, dispatch),
    groupMemberActions: bindActionCreators(groupMemberActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupPage);

import * as types from "../actionTypes/groupActionTypes";
import { apiAuthRequest } from "./helpers";
import { loadSingleGroup } from "./groupPageActions";
import { toastr } from "react-redux-toastr";

function removeGroupMemberRequest(requestId) {
  return {
    type: types.REMOVE_GROUPMEMBER_REQUEST,
    requestId,
  };
}

function addInvitedMemberToGroupSuccess(invite) {
  return {
    type: types.ADD_GROUPMEMBER_INVITE_SUCCESS,
    invite,
  };
}

function addRequestToRequestList(requestMember) {
  return {
    type: types.ADD_GROUPMEMBER_REQUEST_REQUESTS_SUCCESS,
    requestMember,
    analytics: {
      event: "request_join_group",
      params: {
        groupId: requestMember.groupId,
      },
    },
  };
}

function groupMemberInviteAccepted(groupId) {
  return {
    type: types.ACCEPT_GROUP_MEMBER_INVITE,
    groupId: groupId,
    analytics: {
      event: "accept_group_invite",
      params: {
        groupId: groupId,
      },
    },
  };
}

function updateGroupMemberAccessLevelFail(errorMessage) {
  return {
    type: types.UPDATE_GROUPMEMBER_FAIL,
    errorMessage,
  };
}

export function inviteMemberToGroup(memberInfo, closeModal) {
  //groupId, memberEmail, groupAccessLevel
  return async (dispatch) => {
    let result = await apiAuthRequest(
      "post",
      "/groupMembers/inviteMemberToGroup",
      memberInfo,
    );
    if (result.success) {
      dispatch(addInvitedMemberToGroupSuccess(result.data));
    } else {
      console.error(result.data);
      toastr.error("Error inviting member to group");
    }
    setTimeout(closeModal, 3000);
  };
}

export function resendInviteMemberToGroup(inviteId) {
  return async (dispatch) => {
    let result = await apiAuthRequest(
      "post",
      "/groupMembers/resendInviteToGroup",
      { id: inviteId },
    );
    if (result.success) {
      dispatch({
        type: types.RESEND_GROUPMEMBER_INVITE_SUCCESS,
        inviteId,
      });
    } else {
      console.error(result.data);
      toastr.error("Error resending invite");
    }
  };
}

export function withdrawInviteMemberToGroup(inviteId) {
  return async (dispatch) => {
    let result = await apiAuthRequest(
      "post",
      "/groupMembers/withdrawInviteMemberToGroup",
      { id: inviteId },
    );
    if (result.success) {
      dispatch({
        type: types.WITHDRAW_GROUPMEMBER_INVITE_SUCCESS,
        inviteId,
      });
    } else {
      console.error(result.data);
      toastr.error("Error withdrawing invitation");
    }
  };
}

export function acceptGroupMemberInvite(inviteId) {
  return async (dispatch) => {
    let result = await apiAuthRequest(
      "post",
      "/groupMembers/acceptGroupMemberInvite",
      { id: inviteId },
    );
    if (result.success) {
      if (result.data.needsApproval) {
        toastr.info(
          "To accept this invitation as a group owner you must first become a verified user",
        );
      } else {
        dispatch(loadSingleGroup(result.data.groupId));
        dispatch(groupMemberInviteAccepted(result.data.groupId));
      }
    } else {
      console.error(result.data);
      toastr.error("Error accepting invitation");
    }
  };
}

export function requestToJoinGroup(groupId) {
  return async (dispatch) => {
    let result = await apiAuthRequest(
      "post",
      "/groupMembers/requestToJoinGroup",
      { id: groupId },
    );
    if (result.success) {
      if (result.data.memberAccepted) {
        dispatch(loadSingleGroup(groupId));
        dispatch(groupMemberInviteAccepted(groupId));
      } else {
        dispatch(addRequestToRequestList(result.data));
      }
    } else {
      console.error(result.data);
      toastr.error("Error submitting request to join group");
    }
  };
}

export function acceptGroupMemberRequest(requestId) {
  return async (dispatch) => {
    let result = await apiAuthRequest(
      "post",
      "/groupMembers/acceptGroupMemberRequest",
      { id: requestId },
    );
    if (result.success) {
      if (result.data.memberAlreadyExists) {
        dispatch(removeGroupMemberRequest(requestId));
      }
      dispatch({
        type: types.ADD_GROUPMEMBER_REQUEST_SUCCESS,
        requestId,
        member: result.data,
      });
    } else {
      console.error(result.data);
      toastr.error("Error accepting member request");
    }
  };
}

export function declineGroupMemberRequest(requestId) {
  return async (dispatch) => {
    let result = await apiAuthRequest(
      "post",
      "/groupMembers/declineGroupMemberRequest",
      { id: requestId },
    );
    if (result.success) {
      dispatch(removeGroupMemberRequest(result.data.requestId));
    } else {
      console.error(result.data);
      toastr.error("Error declining member request");
    }
  };
}

export function removeMemberFromGroup(groupMemberId) {
  return async (dispatch) => {
    let result = await apiAuthRequest("post", "/groupMembers/removeMember", {
      id: groupMemberId,
    });
    if (result.success) {
      dispatch({
        type: types.REMOVE_GROUPMEMBER_SUCCESS,
        id: groupMemberId,
        groupId: result.data.groupId,
      });
    } else {
      console.error(result.data);
      toastr.error("Error removing member from group");
    }
  };
}

export function leaveMemberFromGroup(groupId) {
  return async (dispatch) => {
    let result = await apiAuthRequest("post", "/groupMembers/leaveMember", {
      id: groupId,
    });
    if (result.success) {
      dispatch({
        type: types.LEAVE_GROUPMEMBER_SUCCESS,
        id: result.data.removedMemberId,
        groupId,
      });
    } else {
      console.error(result.data);
      toastr.error("Error leaving group");
    }
  };
}

export function updateGroupMemberAccessLevel(
  groupMemberId,
  updatedAccessLevel,
) {
  return async (dispatch) => {
    let result = await apiAuthRequest(
      "post",
      "/groupMembers/changeMemberAccessLevel",
      {
        groupMemberId,
        access: updatedAccessLevel,
      },
    );
    if (result.success && result.data.updatedGroupMember) {
      dispatch({
        type: types.UPDATE_GROUPMEMBER_SUCCESS,
        updatedMember: result.data.updatedGroupMember,
      });
    } else {
      if (result.data.invite) {
        dispatch(addInvitedMemberToGroupSuccess(result.data.invite));
      }
      dispatch({
        type: types.UPDATE_GROUPMEMBER_FAIL,
        errorMessage: result.data.message,
      });
      setTimeout(() => {
        dispatch({ type: types.CLEAR_GROUP_ERROR_MESSAGE });
      }, 8000);
    }
  };
}

import React from "react";
import DropdownList from "react-widgets/lib/DropdownList";
import FontAwesome from "react-fontawesome";
import { apiAuthRequest } from "../../../actions/helpers";

function renderSuggestion(suggestion) {
  let group = suggestion.item;
  return (
    <div>
      <div>
        <img
          className="autosuggest-list-item-image"
          src={
            group.logoLocation
              ? group.logoLocation
              : "/images/small-placeholder.png"
          }
        />
      </div>
      <div className="autosuggest-list-item-info">
        <h5 key={group.id}>{group.name}</h5>
        <ul>
          {group.admins &&
            group.admins.map((m) => (
              <li key={m.id}>
                {m.users.firstName} {m.users.lastName}
              </li>
            ))}
        </ul>
      </div>
      {group.hasResume ? (
        <div className="resume-status">
          <p className="has-resume">
            <FontAwesome name="check" />
            Resume
          </p>
        </div>
      ) : null}
      {group.isMember ? (
        <div className="resume-status">
          <p className="is-member">
            <FontAwesome name="check" />
            Member
          </p>
        </div>
      ) : null}
    </div>
  );
}

export default class groupDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "",
      suggestions: [],
      isLoading: false,
    };
    this.lastRequestId = null;
  }

  componentDidMount() {
    this.loadSuggestions("");
  }

  loadSuggestions(value) {
    // Cancel the previous request
    if (this.lastRequestId !== null) {
      clearTimeout(this.lastRequestId);
    }

    this.setState({
      isLoading: true,
    });

    let params = { query: value, resumeId: this.props.resumeId };
    let inside = this;
    apiAuthRequest("get", "/groups/autocomplete", params).then((result) => {
      if (inside.refs.autocomplete && result.success) {
        inside.setState({
          isLoading: false,
          suggestions: result.data,
        });
      }
    });
  }

  onChange(value) {
    this.props.onSelect(value);
    this.setState({ value: value.name });
  }

  render() {
    const { suggestions, isLoading } = this.state;

    return (
      <div ref="autocomplete" className="container autosuggest-dropdown">
        <DropdownList
          busy={isLoading}
          data={suggestions}
          textField="name"
          valueField="id"
          onSearch={this.loadSuggestions.bind(this)}
          onChange={this.onChange.bind(this)}
          itemComponent={renderSuggestion}
          filter={(t) => t}
        />
      </div>
    );
  }
}

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as groupPageActions from "../../actions/groupPageActions";
// import Infinite from "../common/Infinite";
// import _ from "lodash";

const emptySearch = {
  pageNumber: 1,
  filterBy: "all",
  searchText: "",
};

class GroupsListPanel extends Component {
  static contextTypes = {
    objectHeights: PropTypes.object.isRequired,
    recalculateObjectHeights: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      search: {
        ...emptySearch,
        pageNumber: props.pagination.page,
      },
      filterActive: false,
      searchActive: false,
    };
    // this.reload = this.reload.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    // this.loadMoreGroups = this.loadMoreGroups.bind(this);
    this.filterDropDown = this.filterDropDown.bind(this);
    this.inputChanged = this.inputChanged.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount() {
    // if (this.props.search) {
    //   let previousSearch = { ...this.state.search, ...this.props.search };
    //   this.setState({ search: previousSearch }, () => {
    //     this.reload();
    //   });
    // }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.location.hash !== nextProps.location.hash) {
      //if coming from a notification, refresh data
      this.reload();
    }
    if (nextProps.displayMobile !== this.props.displayMobile) {
      nextContext.recalculateObjectHeights();
    }
  }

  filterDropDown(event) {
    let search = { ...this.state.search };
    search.filterBy = event.target.value;
    this.setState({ search }, () => {
      this.reload();
    });
  }

  // reload() {
  //   let search = { ...this.state.search };
  //   search.pageNumber = 1;
  //   let searchActive = !_.isEqual(search, emptySearch);
  //   this.setState({ search, searchActive }, () => {
  //     this.props.actions.loadGroups(search);
  //     this.context.recalculateObjectHeights();
  //   });
  // }

  clearSearch() {
    let search = { ...emptySearch };
    this.setState({ search }, () => {
      this.reload();
    });
  }

  inputChanged(event) {
    const field = event.target.name;
    let search = { ...this.state.search };
    search[field] = event.target.value;
    return this.setState({ search });
  }

  handleKeyPress(e) {
    if (e.key === "Enter") {
      this.reload();
    }
  }

  // loadMoreGroups() {
  //   if (this.state.search.pageNumber > this.props.pagination.pageCount + 1) {
  //     return;
  //   }
  //   if (this.state.search.pageNumber >= this.props.pagination.page + 2) {
  //     //don't load too many pages ahead
  //     return;
  //   }
  //   let search = { ...this.state.search };
  //   search.pageNumber = search.pageNumber + 1;
  //   this.setState({ search }, () => {
  //     this.props.actions.loadGroups(search);
  //   });
  // }

  groupRow(group) {
    if (!Object.keys(group).length) return null;
    let groupImage = group.logoLocation
      ? `/api/images/group/${group.logoLocation}`
      : "/images/small-placeholder.png";

    let maxLimit = 22;
    return (
      <li
        key={group.id}
        className="sidebar-list-item sidebar-list-item-active"
        // onClick={() => this.props.selectGroup(group.id)}
      >
        <span className="sidebar-column-small"></span>
        <div className="sidebar-img-container">
          <img src={groupImage} alt="" className="sidebar-list-item-image" />
        </div>
        <div className="sidebar-list-item-info sidebar-column-large">
          <h5 className="sidebar-list-item-title">
            {group.name.length > maxLimit
              ? group.name.substring(0, maxLimit - 3) + "..."
              : group.name}
          </h5>
          {/*<p className="sidebar-list-item-info-details sidebar-list-item-info-details-small">*/}
          {/*<span>{group.membersCount} {group.membersCount > 1 || !group.membersCount ? 'Members' : 'Member'}</span>*/}
          {/*</p>*/}
        </div>
        <div className="sidebar-list-item-extra sidebar-column-medium">
          {group.isMember ? <span className="border">Member</span> : null}
          {group.hasMyResume ? <span className="border">Resume</span> : null}
          {group.invited ? <span className="border">Invited</span> : null}
          {group.approved ? null : <span className="pending">Pending</span>}
        </div>
      </li>
    );
  }

  render() {
    // let groupsCount = this.props.pagination.rowCount;
    return (
      <div
        id="sidebar-left"
        className={`header-padding ${
          this.props.displayMobile ? "" : "mobile-hide"
        }`}
      >
        {/* <div className="search-filter" id="search-filter">
          <div className="search-bar">
            <div className="search">
              <input
                className="input"
                type="text"
                onChange={this.inputChanged}
                onBlur={this.reload}
                onKeyPress={this.handleKeyPress}
                value={this.state.search.searchText}
                placeholder="Search Groups"
                name="searchText"
              />
            </div>
          </div>
        </div>
        <div id="filter-bar" className="filter-bar">
          <div className="filter-column-dropdown group-filter">
            <p>Include:</p>
            <select
              className="inbox-settings-select mui-select"
              onChange={this.filterDropDown}
              value={this.state.search.filterBy}
            >
              <option className="option" value="all" label="All" />
              <option
                className="option"
                value="groupMember"
                label="Groups I am a member of"
              />
              {this.props.hasVerifiedResume ? (
                <option
                  className="option"
                  value="groupResume"
                  label="Groups my resume is shared with"
                />
              ) : null}
              <option
                className="option"
                value="groupInvite"
                label="Groups I am a invited to"
              />
            </select>
          </div>
        </div>
        {this.state.searchActive ? (
          <div className="sidebar-list-results" id="sidebar-list-results">
            <p>
              <span className="sidebar-list-results-bold">{groupsCount} </span>
              Result{groupsCount === 1 ? "" : "s"}
            </p>
            <p onClick={this.clearSearch} className="" type="button">
              <span>Clear filters</span>
            </p>
          </div>
        ) : (
          ""
        )} */}
        <div className="sidebar-list" id="resumeList">
          <ul>
            {/* <Infinite
              containerHeight={
                this.context.objectHeights.sidebarCalculatedHeight
              }
              hasMore={groupsCount > this.props.groups.length}
              className="infinite"
              loadingMore={!!this.props.isLoading}
              itemList={this.props.groups}
              renderRow={this.groupRow.bind(this)}
              noItemsText="No groups."
              loadMore={this.loadMoreGroups.bind(this)}
            /> */}
            {this.groupRow(this.props.group)}
          </ul>
        </div>
        {/* <div className="sidebar-list-count" id="sidebar-list-count">
          <p>
            {groupsCount} Group{groupsCount > 1 ? "s" : ""}
          </p>
        </div> */}
      </div>
    );
  }
}

GroupsListPanel.propTypes = {
  search: PropTypes.object.isRequired,
  pagination: PropTypes.object.isRequired,
  isLoading: PropTypes.number,
  hasVerifiedResume: PropTypes.number,
  selectGroup: PropTypes.func.isRequired,
  selectedGroup: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  location: PropTypes.object.isRequired,
  displayMobile: PropTypes.bool,
  switchMobileTab: PropTypes.func.isRequired,
  actions: PropTypes.shape({
    loadGroups: PropTypes.func.isRequired,
  }),
};

function mapStateToProps(state) {
  return {
    group: state.singleGroup.group,
    search: state.groups.search,
    pagination: state.groups.pagination,
    isLoading: state.groups.isLoading,
    hasVerifiedResume: state.user.user.hasVerifiedResume,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(groupPageActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupsListPanel);

import _ from "lodash";
import React, { Component, Children } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";

/**
 * A component that allows you to render children outside their parent.
 */
class Portal extends Component {
  state = {};

  componentDidMount() {
    this.renderPortal();
  }

  componentDidUpdate(prevProps, prevState) {
    // NOTE: Ideally the portal rendering would happen in the render() function
    // but React gives a warning about not being pure and suggests doing it
    // within this method.

    // If the portal is open, render (or re-render) the portal and child.
    this.renderPortal();

    if (prevState.open && !this.state.open) {
      this.unmountPortal();
    }
  }

  componentWillUnmount() {
    this.unmountPortal();

    // Clean up timers
    clearTimeout(this.mouseEnterTimer);
    clearTimeout(this.mouseLeaveTimer);
  }

  // ----------------------------------------
  // Document Event Handlers
  // ----------------------------------------

  handleDocumentClick = (e) => {
    e.stopPropagation();
    this.close(e);
  };

  handleEscape = (e) => {
    e.preventDefault();
    this.close(e);
  };

  // ----------------------------------------
  // Component Event Handlers
  // ----------------------------------------

  handlePortalMouseLeave = (e) => {
    this.mouseLeaveTimer = this.closeWithTimeout(e, 70);
  };

  handlePortalMouseEnter = (e) => {
    clearTimeout(this.mouseLeaveTimer);
  };

  handleTriggerBlur = (e) => {
    this.close(e);
  };

  handleTriggerClick = (e) => {
    const { trigger } = this.props;
    const { open } = this.state;

    // Call original event handler
    _.invoke(trigger, "props.onClick", e);

    if (!open) {
      e.stopPropagation();
      this.open(e);
    }

    // Prevents handleDocumentClick from closing the portal when
    // openOnTriggerFocus is set. Focus shifts on mousedown so the portal opens
    // before the click finishes so it may actually wind up on the document.
    e.nativeEvent.stopImmediatePropagation();
  };

  handleTriggerFocus = (e) => {
    const { trigger } = this.props;

    // Call original event handler
    _.invoke(trigger, "props.onFocus", e);

    this.open(e);
  };

  handleTriggerMouseLeave = (e) => {
    clearTimeout(this.mouseEnterTimer);

    const { trigger } = this.props;

    // Call original event handler
    _.invoke(trigger, "props.onMouseLeave", e);

    this.mouseLeaveTimer = this.closeWithTimeout(e, 70);
  };

  handleTriggerMouseEnter = (e) => {
    clearTimeout(this.mouseLeaveTimer);

    const { trigger } = this.props;

    // Call original event handler
    _.invoke(trigger, "props.onMouseEnter", e);

    this.mouseEnterTimer = this.openWithTimeout(e, 50);
  };

  // ----------------------------------------
  // Behavior
  // ----------------------------------------

  open = (e) => {
    const { onOpen } = this.props;
    if (onOpen) onOpen(e, this.props);

    this.setState({ open: true });
  };

  openWithTimeout = (e, delay) => {
    // React wipes the entire event object and suggests using e.persist() if
    // you need the event for async access. However, even with e.persist
    // certain required props (e.g. currentTarget) are null so we're forced to clone.
    const eventClone = { ...e };
    return setTimeout(() => this.open(eventClone), delay || 0);
  };

  close = (e) => {
    this.setState({ open: false });
  };

  closeWithTimeout = (e, delay) => {
    // React wipes the entire event object and suggests using e.persist() if
    // you need the event for async access. However, even with e.persist
    // certain required props (e.g. currentTarget) are null so we're forced to clone.
    const eventClone = { ...e };
    return setTimeout(() => this.close(eventClone), delay || 0);
  };

  renderPortal() {
    if (!this.state.open) return;

    const { children } = this.props;

    this.mountPortal();

    // when re-rendering, first remove listeners before re-adding them to the new node
    if (this.portal) {
      this.portal.removeEventListener(
        "mouseleave",
        this.handlePortalMouseLeave,
      );
      this.portal.removeEventListener(
        "mouseenter",
        this.handlePortalMouseEnter,
      );
    }

    ReactDOM.unstable_renderSubtreeIntoContainer(
      this,
      Children.only(children),
      this.node,
    );

    this.portal = this.node.firstElementChild;
    if (this.portal) {
      this.portal.addEventListener("mouseleave", this.handlePortalMouseLeave);
      this.portal.addEventListener("mouseenter", this.handlePortalMouseEnter);
    }
  }

  mountPortal = () => {
    if (this.node) return;

    const { mountNode } = this.props;

    this.node = document.createElement("div");

    mountNode.appendChild(this.node);

    document.addEventListener("click", this.handleDocumentClick);
    document.addEventListener("keydown", this.handleEscape);

    const { onMount } = this.props;
    if (onMount) onMount(null, this.props);
  };

  unmountPortal = () => {
    if (!this.node) return;

    ReactDOM.unmountComponentAtNode(this.node);
    this.node.parentNode.removeChild(this.node);

    this.portal.removeEventListener("mouseleave", this.handlePortalMouseLeave);
    this.portal.removeEventListener("mouseenter", this.handlePortalMouseEnter);

    this.node = null;
    this.portal = null;

    document.removeEventListener("click", this.handleDocumentClick);
    document.removeEventListener("keydown", this.handleEscape);
  };

  render() {
    const { trigger } = this.props;

    if (!trigger) return null;

    return React.cloneElement(trigger, {
      onBlur: this.handleTriggerBlur,
      onClick: this.handleTriggerClick,
      onFocus: this.handleTriggerFocus,
      onMouseLeave: this.handleTriggerMouseLeave,
      onMouseEnter: this.handleTriggerMouseEnter,
    });
  }
}

Portal.propTypes = {
  /** Primary content. */
  children: PropTypes.node.isRequired,

  /** The node where the portal should mount. */
  mountNode: PropTypes.any,

  onMount: PropTypes.func,

  onOpen: PropTypes.func,

  /** Element to be rendered in-place where the portal is defined. */
  trigger: PropTypes.node,
};

Portal.defaultProps = {
  mountNode: document.body,
};

export default Portal;

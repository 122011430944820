import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as welcomeActions from "../../actions/welcomeActions";

const welcomeSections = {
  Resumes: {
    text: "Here you can view resumes you have created or have access to.",
    image: "/images/tour-Resumes-modal.png",
  },
  Inbox: {
    text:
      "Here you can send and receive messages about resumes right in ZivugTech! ",
    image: "/images/tour-Inbox-modal.png",
  },
  Ideas: {
    text:
      "Here you can create ideas and keep track of their status by moving cards to the next stage.",
    image: "/images/tour-Ideas-modal.png",
  },
  Groups: {
    text:
      "Here you can view groups, add resumes to groups or view groups you are a member of.",
    image: "/images/tour-Groups-modal.png",
  },
};

class welcomeContainer extends React.Component {
  static childContextTypes = {
    showWelcome: PropTypes.func.isRequired,
  };

  getChildContext() {
    return {
      showWelcome: this.getWelcome.bind(this),
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      welcomeSection: null,
      welcomeClosed: false,
    };
  }

  getWelcome = async (section) => {
    if (!this.props.welcomeLoaded) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return await this.getWelcome(section);
    }
    if (
      section !== this.state.welcomeSection &&
      !this.props.steps.find((w) => w.type === section)
    ) {
      this.setState({ welcomeSection: section, welcomeClosed: false });
    }
  };

  welcomeCompleted(section) {
    console.log("finished", section);
    if (!this.props.steps.find((w) => w.type === section)) {
      this.props.actions.addWelcome(section);
    }
    this.setState({ welcomeClosed: true });
  }

  handleOnOutsideClick(e) {
    if (!this.isChildOf(e.target, this.refs.welcomeModal) || false) {
      this.setState({ welcomeClosed: true });
    }
  }

  isChildOf(child, parent) {
    if (child.parentNode === parent) {
      return true;
    } else if (child.parentNode === null) {
      return false;
    } else {
      return this.isChildOf(child.parentNode, parent);
    }
  }

  render() {
    const sectionData = welcomeSections[this.state.welcomeSection];
    return (
      <div style={{ height: "100%" }}>
        {this.state.welcomeSection && !this.state.welcomeClosed ? (
          <div className="react-redux-modal welcome-modal">
            <div className="rr-modals">
              <div className="rrm-holder" style={{ zIndex: `999` }}>
                <div
                  className="scroll"
                  onClick={this.handleOnOutsideClick.bind(this)}
                >
                  <div ref="welcomeModal" className="rrm-content m-medium">
                    <div className="rrm-body">
                      <div className="">
                        <i
                          className="material-icons welcome-modal-close"
                          onClick={() => this.setState({ welcomeClosed: true })}
                        >
                          close
                        </i>
                      </div>
                      <img src={sectionData.image} />
                      <div className="welcome-modal-footer">
                        <p className="welcome-modal-title">
                          {this.state.welcomeSection}
                        </p>
                        <p className="demo-text">{sectionData.text}</p>
                        <label
                          className="dont-show"
                          onClick={() =>
                            this.welcomeCompleted(this.state.welcomeSection)
                          }
                        >
                          Don't show this again
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="rrm-shadow" />
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {this.props.children}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(welcomeActions, dispatch),
  };
}

const mapStateToProps = (state) => ({
  steps: state.welcome.steps,
  welcomeLoaded: state.welcome.welcomeLoaded,
});

export default connect(mapStateToProps, mapDispatchToProps)(welcomeContainer);

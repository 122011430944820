export const LOAD_GROUPS_START = "LOAD_GROUPS_START";
export const LOAD_GROUPS_SUCCESS = "LOAD_GROUPS_SUCCESS";
export const LOAD_GROUPS_FAIL = "LOAD_GROUPS_FAIL";
export const GET_GROUP_START = "GET_GROUP_START";
export const GET_GROUP_SUCCESS = "GET_GROUP_SUCCESS";
export const GET_GROUP_FAIL = "GET_GROUP_FAIL";
export const GET_GROUP_RESUMES_SUCCESS = "GET_GROUP_RESUMES_SUCCESS";

//GROUP RESUMES
export const SHARE_RESUME_TO_GROUP = "SHARE_RESUME_TO_GROUP";
export const APROVE_GROUPRESUME_SUCCESS = "APROVE_GROUPRESUME_SUCCESS";
export const ADD_GROUPMYRESUME_SUCCESS = "ADD_GROUPMYRESUME_SUCCESS";
export const ADD_GROUPMYRESUME_PENDING = "ADD_GROUPMYRESUME_PENDING";
export const REMOVE_GROUPRESUME_SUCCESS = "REMOVE_GROUPRESUME_SUCCESS";

//GROUP MEMBERS
export const ADD_GROUPMEMBER_REQUEST_SUCCESS =
  "ADD_GROUPMEMBER_REQUEST_SUCCESS";
export const ADD_GROUPMEMBER_REQUEST_REQUESTS_SUCCESS =
  "ADD_GROUPMEMBER_REQUEST_REQUESTS_SUCCESS";
export const ADD_GROUPMEMBER_INVITE_SUCCESS = "ADD_GROUPMEMBER_INVITE_SUCCESS";
export const RESEND_GROUPMEMBER_INVITE_SUCCESS =
  "RESEND_GROUPMEMBER_INVITE_SUCCESS";
export const WITHDRAW_GROUPMEMBER_INVITE_SUCCESS =
  "WITHDRAW_GROUPMEMBER_INVITE_SUCCESS";
export const ACCEPT_GROUP_MEMBER_INVITE = "ACCEPT_GROUP_MEMBER_INVITE";
export const UPDATE_GROUPMEMBER_SUCCESS = "UPDATE_GROUPMEMBER_SUCCESS";
export const UPDATE_GROUPMEMBER_FAIL = "UPDATE_GROUPMEMBER_FAIL";
export const REMOVE_GROUPMEMBER_SUCCESS = "REMOVE_GROUPMEMBER_SUCCESS";
export const REMOVE_GROUPMEMBER_REQUEST = "REMOVE_GROUPMEMBER_REQUEST";
export const LEAVE_GROUPMEMBER_SUCCESS = "LEAVE_GROUPMEMBER_SUCCESS";

//FLAG GROUP
export const FLAG_GROUP = "FLAG_GROUP";
export const UNFLAG_GROUP = "UNFLAG_GROUP";

export const CLEAR_GROUP_ERROR_MESSAGE = "CLEAR_GROUP_ERROR_MESSAGE";

//EDIT GROUP
export const GET_GROUP_EDIT_START = "GET_GROUP_EDIT_START";
export const GET_GROUP_EDIT_SUCCESS = "GET_GROUP_EDIT_SUCCESS";
export const GET_GROUP_EDIT_FAIL = "GET_GROUP_EDIT_FAIL";
export const EDIT_GROUP_START = "EDIT_GROUP_START";
export const EDIT_GROUP_SUCCESS = "EDIT_GROUP_SUCCESS";
export const EDIT_GROUP_FAIL = "EDIT_GROUP_FAIL";

//CREATE GROUP
export const CREATE_GROUP_START = "CREATE_GROUP_START";
export const CREATE_GROUP_SUCCESS = "CREATE_GROUP_SUCCESS";
export const CREATE_GROUP_FAIL = "CREATE_GROUP_FAIL";

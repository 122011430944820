import React from "react";
import PropTypes from "prop-types";

class FlagModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flag: {
        flaggedId: props.flaggedId,
        message: "",
      },
      flagged: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  save(e) {
    if (!this.state.flag.message) {
      return this.setState({ missingMessage: true });
    }
    e.preventDefault();
    let inside = this;
    this.setState({ flagged: true }, () => {
      this.props.flagAction(inside.state.flag, inside.props.removeModal);
    });
  }

  handleChange(e) {
    const field = e.target.name;
    let flag = this.state.flag;
    flag[field] = e.target.value;
    return this.setState({ flag, missingMessage: false });
  }

  onBlur(event) {
    if (!event.target.value) {
      return this.setState({ missingMessage: true });
    }
    this.setState({ missingMessage: false });
  }

  render() {
    if (this.state.flagged) {
      return (
        <div className="mui-container-fluid">
          <div className="mui-row">
            <i
              className="material-icons modal-close-icon"
              onClick={this.props.removeModal.bind(this)}
            >
              close
            </i>
            <div className="modal-response">
              <div className="envelope">
                <div className="envelope-outline">
                  <div className="triangle"></div>
                </div>
                <div className="dot dot1"></div>
                <div className="dot dot2"></div>
                <div className="dot dot3"></div>
                <div className="dot dot4"></div>
                <div className="dot dot5"></div>
                <div className="dot dot6"></div>
              </div>
              <p>{this.props.flaggedName} has been reported</p>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="medium-modal">
        <div className="flag-modal">
          <div className="modal-header mui-row">
            <p>
              Report {this.props.flaggedType}: {this.props.flaggedName}{" "}
            </p>
            <i
              className="material-icons modal-close-icon"
              onClick={this.props.removeModal.bind(this)}
            >
              close
            </i>
          </div>
          <div className="modal-content-container group-modal-form" id="form">
            <legend>
              Please fill out a reason for the report
              <span className="has-error">
                {this.state.missingMessage ? "Required" : ""}
              </span>
            </legend>
            <textarea
              placeholder="Reason for report"
              name="message"
              ref="reason"
              type="text"
              value={this.state.flag.message}
              onChange={this.handleChange}
              onBlur={this.onBlur.bind(this)}
              className="form-input-margin-bottom"
            />
            <button
              onClick={this.save.bind(this)}
              className="btn-flat btn-accentPrimary btn-fullWidth"
            >
              Report {this.props.flaggedType}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

FlagModal.propTypes = {
  flaggedName: PropTypes.string.isRequired,
  flaggedType: PropTypes.string.isRequired,
  flaggedId: PropTypes.number.isRequired,
  flagAction: PropTypes.func.isRequired,
  removeModal: PropTypes.func.isRequired,
};

export default FlagModal;

import React, { Component } from "react";

export default class NotificationItem extends Component {
  constructor(props) {
    super(props);
  }

  handleOnClick() {
    if (this.props.onClick) {
      this.props.onClick(this.props);
    }
  }

  render() {
    return (
      <li
        className={`rn-item ${this.props.new ? "new" : ""}`}
        onClick={this.handleOnClick.bind(this)}
      >
        <p className="short-desc">
          {this.props.tags &&
            this.props.tags.map((item, i) => (
              <span className={`notification-tag ${item.type}`} key={i}>
                {item.text}
              </span>
            ))}
          {this.props.title && (
            <strong className="title">{this.props.title} </strong>
          )}
          {this.props.message}
          {this.props.date && <small className="date">{this.props.date}</small>}
        </p>
      </li>
    );
  }
}

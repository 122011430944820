import { gAnalyticsKey } from "../config";

export const analyticsMiddleware = (store) => (next) => (action) => {
  if (action.analytics && process.env.NODE_ENV === "production") {
    const { event, params, userId } = action.analytics;
    if (event) {
      console.log("Send event:", event, params);
      if (window.gtag) {
        window.gtag("event", event, params);
      }
    }
    if (userId) {
      if (window.gtag) {
        window.gtag("config", gAnalyticsKey, {
          user_id: userId,
        });
      }
    }
  }
  next(action);
};

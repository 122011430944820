import * as types from "../actionTypes/ideasActionTypes";
import * as shareTypes from "../actionTypes/shareActionTypes";

export default function ideasReducer(
  state = { ideas: [], statusList: [], stateList: [], filteredIdeas: [] },
  action,
) {
  switch (action.type) {
    case types.GET_IDEAS_SUCCESS:
      return {
        ...state,
        ideas: action.ideas,
      };
    case types.SEARCH_IDEAS:
      return {
        ...state,
        filteredIdeas: state.ideas.map((list) => {
          let newCards = [];
          let searchQuery = action.query.toLowerCase().trim().split(" ");
          list.cards.forEach((card) => {
            for (let i = 0; i < searchQuery.length; i++) {
              let searchText = searchQuery[i];
              if (card.hisResume && card.hisResume.firstName) {
                if (
                  card.hisResume.firstName.toLowerCase().includes(searchText) ||
                  card.hisResume.lastName.toLowerCase().includes(searchText)
                ) {
                  newCards.push(card);
                  return;
                }
              } else if (card.hisName.toLowerCase().includes(searchText)) {
                newCards.push(card);
                return;
              }
              if (card.herResume && card.herResume.firstName) {
                if (
                  card.herResume.firstName.toLowerCase().includes(searchText) ||
                  card.herResume.lastName.toLowerCase().includes(searchText)
                ) {
                  newCards.push(card);
                  return;
                }
              } else if (card.herName.toLowerCase().includes(searchText)) {
                newCards.push(card);
                return;
              }
            }
          });
          return {
            name: list.name,
            cards: newCards,
          };
        }),
      };
    case types.GET_IDEAS_CONSTANTS_SUCCESS:
      return {
        ...state,
        statusList: action.constants.statusList,
        stateList: action.constants.stateList,
      };
    case types.MOVE_CARD: {
      const newIdeas = [...state.ideas];
      const { lastX, lastY, nextX, nextY, nextStateId } = action;
      if (lastX === nextX) {
        newIdeas[lastX].cards.splice(
          nextY,
          0,
          newIdeas[lastX].cards.splice(lastY, 1)[0],
        );
      } else {
        let movedCard = newIdeas[lastX].cards[lastY];
        //change state
        movedCard.ideaStateId = nextStateId;
        // move card to new place
        newIdeas[nextX].cards.splice(nextY, 0, movedCard);
        // delete card from old place
        newIdeas[lastX].cards.splice(lastY, 1);
      }
      return {
        ...state,
        filteredIdeas: [],
        ideas: newIdeas,
      };
    }
    case types.EDIT_IDEA_SUCCESS: {
      if (!state.ideas.length) {
        return state;
      }
      let newIdeas = [...state.ideas];
      //if card moved columns
      if (action.idea.positionParentId === null) {
        //remove card from old position
        newIdeas = newIdeas.map((list) => {
          return {
            ...list,
            cards: list.cards.filter((card) => card.id !== action.idea.id),
          };
        });
        //columns are 0 based
        let column = action.idea.ideaStateId - 1;
        //move card to top of new column
        newIdeas[column].cards.splice(0, 0, action.idea);
      }
      //card stayed in the same place
      else {
        //replace with edited card
        newIdeas = newIdeas.map((list) => {
          return {
            ...list,
            cards: list.cards.map((card) => {
              if (card.id !== action.idea.id) {
                return card;
              }
              return action.idea;
            }),
          };
        });
      }

      return {
        ...state,
        filteredIdeas: [],
        ideas: newIdeas,
      };
    }
    case types.CREATE_IDEA_SUCCESS: {
      if (!state.ideas.length) {
        return state;
      }
      const newIdeas = [...state.ideas];

      //columns are 0 based
      let column = action.idea.ideaStateId - 1;
      //move card to top of the column
      newIdeas[column].cards.splice(0, 0, action.idea);

      return {
        ...state,
        filteredIdeas: [],
        ideas: newIdeas,
      };
    }
    case types.ARCHIVE_IDEA_SUCCESS: {
      return {
        ...state,
        filteredIdeas: [],
        ideas: state.ideas.map((list) => {
          return {
            ...list,
            cards: list.cards.filter((card) => card.id !== action.ideaId),
          };
        }),
      };
    }
    case shareTypes.SHARE_IDEA_START: {
      return {
        ...state,
        isSending: true,
        sentSuccessfully: false,
      };
    }
    case shareTypes.SHARE_IDEA_ERROR: {
      return {
        ...state,
        isSending: false,
        sentSuccessfully: false,
      };
    }
    case shareTypes.SHARE_IDEA_SUCCESS: {
      return {
        ...state,
        isSending: false,
        sentSuccessfully: true,
      };
    }
    default:
      return state;
  }
}

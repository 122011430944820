import * as types from "../actionTypes/resumeActionTypes";
import * as shareTypes from "../actionTypes/shareActionTypes";
import * as ideaTypes from "../actionTypes/ideasActionTypes";
import * as messageTypes from "../actionTypes/sidebarConversationActionTypes";

export default function singleResumeReducer(state = {}, action) {
  switch (action.type) {
    case types.LOAD_SINGLE_RESUME_START:
      return {
        resume: state.resume,
        isLoading: true,
      };
    case types.LOAD_SINGLE_RESUME_SUCCESS:
      return {
        resume: action.resume,
        isLoading: false,
      };
    case types.PIN_RESUME:
      return {
        resume: { ...state.resume, pinned: true },
      };
    case types.UNPIN_RESUME:
      return {
        resume: { ...state.resume, pinned: false },
      };
    case types.FLAG_RESUME:
      return {
        resume: { ...state.resume, flagged: true },
      };
    case types.UNFLAG_RESUME:
      return {
        resume: { ...state.resume, flagged: false },
      };
    case shareTypes.SHARE_RESUME_START:
      return {
        ...state,
        isSharingResume: true,
        sentToUserSuccessfully: false,
      };
    case shareTypes.SHARE_RESUME_ERROR:
      return {
        ...state,
        isSharingResume: false,
        sentToUserSuccessfully: false,
      };
    case shareTypes.SHARE_RESUME_SUCCESS:
      let isCurrentResume =
        state.resume &&
        state.resume.id &&
        action.resumesShared.includes(state.resume.id);
      return {
        resume: isCurrentResume
          ? {
              ...state.resume,
              shareCount: state.resume.shareCount + 1,
              conversations: [
                action.info.conversation,
                ...state.resume.conversations,
              ],
            }
          : state.resume,
        resumeSentToUser: true,
        isSharingResume: false,
        sentToUserSuccessfully: true,
      };
    case shareTypes.SHARE_TO_GROUP_START:
      return {
        ...state,
        isSharingToGroup: true,
        sharedToGroupSuccessfully: false,
      };
    case shareTypes.SHARE_TO_GROUP_ERROR:
      return {
        ...state,
        isSharingToGroup: false,
        sharedToGroupSuccessfully: false,
      };
    case shareTypes.SHARE_TO_GROUP_SUCCESS:
      return {
        resume:
          state.resume.id === action.groupResume.resumeId
            ? {
                ...state.resume,
                groupResumes: [
                  ...state.resume.groupResumes,
                  action.groupResume,
                ],
              }
            : state.resume,
        resumeSentToGroup: true,
        resumeApprovedToGroup: action.groupResume.approved,
        isSharingToGroup: false,
        sharedToGroupSuccessfully: true,
      };
    case types.ADD_TAG_TO_RESUME:
      return {
        resume: {
          ...state.resume,
          userTags: [...state.resume.userTags, action.tag],
        },
      };
    case types.DELETE_TAG_FROM_RESUME:
      return {
        resume: {
          ...state.resume,
          userTags: [
            ...state.resume.userTags.filter((tag) => tag.id !== action.id),
          ],
        },
      };
    case types.SAVING_NOTE:
      return {
        ...state,
        isSavingNote: true,
      };
    case types.ADD_NOTE_TO_RESUME:
      let note = action.note;
      return {
        resume: { ...state.resume, note },
        isSavingNote: false,
      };
    case types.EDIT_NOTE_SUCCESS:
      return {
        resume: { ...state.resume, note: action.note },
        isSavingNote: false,
      };
    case types.ENGAGED_RESUME_REPORTED:
      return {
        resume: {
          ...state.resume,
          reportedEngaged: state.resume.id === action.resumeId,
        },
        engagementReported: true,
      };
    case ideaTypes.EDIT_IDEA_SUCCESS: {
      if (
        state.resume &&
        state.resume.ideas &&
        [action.idea.hisResumeId, action.idea.herResumeId].includes(
          state.resume.id,
        )
      ) {
        return {
          resume: {
            ...state.resume,
            ideas: state.resume.ideas.map((idea) => {
              if (idea.id === action.idea.id) {
                return action.idea;
              } else {
                return idea;
              }
            }),
          },
        };
      } else {
        return { state };
      }
    }
    case ideaTypes.CREATE_IDEA_SUCCESS: {
      if (state.resume && state.resume.ideas) {
        let ideas = state.resume.ideas;
        if (
          action.idea.hisResumeId === state.resume.id ||
          action.idea.herResumeId === state.resume.id
        ) {
          ideas = [action.idea, ...ideas];
        }
        return {
          resume: { ...state.resume, ideas },
        };
      } else {
        return { state };
      }
    }
    case ideaTypes.ARCHIVE_IDEA_SUCCESS: {
      if (state.resume && state.resume.ideas) {
        return {
          resume: {
            ...state.resume,
            ideas: state.resume.ideas.filter(
              (idea) => idea.id !== action.ideaId,
            ),
          },
        };
      } else {
        return { state };
      }
    }
    case messageTypes.SEND_MESSAGE_ON_SIDEBAR_SUCCESS:
      return {
        resume: {
          ...state.resume,
          conversations: state.resume.conversations.map((c) => {
            if (c.id === action.message.conversationId) {
              c.lastActive = new Date();
            }
            return c;
          }),
        },
      };
    case types.SAVING_GROUP_RESUME_NOTE:
      return {
        ...state,
        resume: {
          ...state.resume,
          groupResumes: state.resume.groupResumes.map((g) => {
            if (g.id === action.groupResumeId) {
              return { ...g, note: { ...g.note, isSaving: true } };
            }
            return g;
          }),
        },
      };
    case types.EDIT_GROUP_RESUME_NOTE_SUCCESS:
      return {
        ...state,
        resume: {
          ...state.resume,
          groupResumes: state.resume.groupResumes.map((g) => {
            if (g.id === action.note.groupResumeId) {
              return { ...g, note: { ...action.note, saved: true } };
            }
            return g;
          }),
        },
      };
    default:
      return state;
  }
}

import React from "react";
import PropTypes from "prop-types";
import FontAwesome from "react-fontawesome";
import Infinite from "../common/Infinite";
import Popup from "../common/Popup";
import _ from "lodash";
import { Scrollbars } from "react-custom-scrollbars";
import { heightToString } from "../common/propsConverter";
import MultiSelect from "react-widgets/lib/Multiselect";
import "react-widgets/lib/less/react-widgets.less";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import PrivateImage from "../common/privateImage";

class ResumeLeftPanel extends React.Component {
  static contextTypes = {
    objectHeights: PropTypes.object.isRequired,
    recalculateObjectHeights: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      search: {
        ...props.emptySearch,
        pageNumber: props.pagination.page,
      },
      searchActive: false,
      filterActive: false,
      height: 0,
    };
    this.filterDropDown = this.filterDropDown.bind(this);
    this.inputChanged = this.inputChanged.bind(this);
    this.selectChanged = this.selectChanged.bind(this);
    this.checkBoxChanged = this.checkBoxChanged.bind(this);
    this.genderChanged = this.genderChanged.bind(this);
    this.typeChanged = this.typeChanged.bind(this);
    this.isTypeSelected = this.isTypeSelected.bind(this);
    this.search = this.search.bind(this);
    this.searchAndClose = this.searchAndClose.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
  }

  componentDidMount() {
    if (this.props.search) {
      let previousSearch = { ...this.state.search, ...this.props.search };
      let searchActive = !_.isEqual(
        { ...previousSearch, pageNumber: 1 },
        this.props.emptySearch,
      );
      this.setState({ search: previousSearch, searchActive });
    }
    // this.calculateHeight(this.context.objectHeights);
    this.context.recalculateObjectHeights();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.displayMobile !== this.props.displayMobile) {
      nextContext.recalculateObjectHeights();
    }
    // this.setState({search: { ...this.state.search, {...nextProps.search} } });
  }

  selectResume(resumeId) {
    this.props.selectResume(resumeId);
  }

  selectNext() {
    if (this.infinite) {
      this.infinite.scrollDown();
    }
  }

  selectPrevious() {
    if (this.infinite) {
      this.infinite.scrollUp();
    }
  }

  filterClick() {
    this.setState(
      { filterActive: !this.state.filterActive },
      this.context.recalculateObjectHeights,
    );
  }

  filterDropDown(event) {
    let search = { ...this.state.search };
    search.filterBy = event.target.value;
    this.setState({ search }, () => {
      this.search();
    });
  }

  unreadChange() {
    let search = { ...this.state.search };
    search.unreadOnly = !search.unreadOnly;
    this.setState({ search }, () => {
      this.search();
    });
  }

  pinnedChange() {
    let search = { ...this.state.search };
    search.pinnedOnly = !search.pinnedOnly;
    this.setState({ search }, () => {
      this.search();
    });
  }

  inputChanged(event) {
    const field = event.target.name;
    let search = { ...this.state.search };
    search[field] = event.target.value;
    return this.setState({ search });
  }

  userTagsChanged(userTags) {
    let search = { ...this.state.search };
    search.userTags = userTags;
    return this.setState({ search });
  }

  groupsChanged(groups) {
    let search = { ...this.state.search };
    search.groups = groups;
    return this.setState({ search });
  }

  selectChanged(event) {
    const field = event.target.name;
    let search = { ...this.state.search };
    search[field] = Number(event.target.value);
    return this.setState({ search });
  }

  checkBoxChanged(event) {
    const field = event.target.name;
    let value = Number(event.target.value);
    let search = { ...this.state.search };
    let list = [...search[field]];
    if (list.includes(value)) {
      list = list.filter((i) => i !== value);
    } else {
      list.push(value);
    }
    search[field] = list;
    return this.setState({ search });
  }

  genderChanged(event) {
    let value = Number(event.target.value);
    let search = { ...this.state.search };
    let gender = null;
    switch (search.gender) {
      case 1:
        gender = value === 1 ? null : 0;
        break;
      case 2:
        gender = value === 1 ? 0 : null;
        break;
      case 0:
        gender = value === 1 ? 2 : 1;
        break;
      default:
        gender = value === 1 ? 1 : 2;
    }
    search.gender = gender;
    return this.setState({ search });
  }

  typeChanged(event) {
    const value = event.target.value;
    let search = { ...this.state.search };
    let types = [...search.types];

    if (this.isTypeSelected(value)) {
      types = types.filter((t) => t.typeId !== value);
    } else {
      types.push({ typeId: value });
    }
    search.types = types;
    return this.setState({ search });
  }

  isTypeSelected(typeId) {
    let search = { ...this.state.search };
    return search.types.some((t) => {
      return parseInt(t.typeId) === parseInt(typeId);
    });
  }

  clearSearch() {
    let search = { ...this.props.emptySearch };
    return this.setState({ search }, () => {
      this.searchAndClose();
      this.context.recalculateObjectHeights();
    });
  }

  handleKeyPress(e) {
    if (e.key === "Enter") {
      this.searchAndClose();
    }
  }

  searchAndClose() {
    this.search();
    this.setState({ filterActive: false });
  }

  search() {
    let search = { ...this.state.search };
    search.pageNumber = 1;
    let searchActive = !_.isEqual(search, this.props.emptySearch);
    this.setState({ search, searchActive }, () => {
      this.props.loadResumes(search);
      this.context.recalculateObjectHeights();
    });
  }

  resumeTypeRow(type, length, index) {
    return index < length - 1 ? type.type.text + ", " : type.type.text;
  }

  resumeRow(resume) {
    return (
      <li
        key={resume.id}
        onClick={() => this.selectResume(resume.id)}
        className={
          this.props.selectedResume === resume.id
            ? "sidebar-list-item sidebar-list-item-active"
            : "sidebar-list-item"
        }
        ref={this.props.selectedResume === resume.id ? "activeItem" : ""}
      >
        <span
          className={
            resume.viewed
              ? "sidebar-list-item-read sidebar-column-small"
              : "sidebar-list-item-unread sidebar-column-small"
          }
        >
          <FontAwesome name="circle" />
        </span>
        <div className="sidebar-img-container">
          <PrivateImage
            fileName={
              resume.images && resume.images.length
                ? resume.images[0].fileLocation
                : ""
            }
            id={resume.id}
            section="resumeSmall"
            className="sidebar-list-item-image"
          />
        </div>
        <div className="sidebar-list-item-info sidebar-column-large">
          <h5 className="sidebar-list-item-title">
            {resume.firstName} {resume.lastName}
          </h5>
          <p className="sidebar-list-item-info-details full-name">
            {resume.fullName ? `(${resume.fullName})` : ""}
          </p>
          <p className="sidebar-list-item-info-details">
            <span>{resume.age || "-"}</span>
            <span>{resume.height}</span>
            <span>
              {resume.maritalStatus}{" "}
              {resume.numberChildren > 0 ? `/ ${resume.numberChildren}` : ""}
            </span>
          </p>
        </div>
        <div className="sidebar-list-item-extra sidebar-column-medium">
          {!resume.published && !resume.engaged ? (
            <FontAwesome name="file-text-o" />
          ) : null}
          {resume.engaged ? <FontAwesome name="diamond" /> : null}
          {resume.pinned ? <FontAwesome name="star" /> : null}
          {resume.isMine ? (
            <div className="sidebar-list-item-myResume">
              <p>My Resume</p>
            </div>
          ) : null}
        </div>
      </li>
    );
  }

  renderFilterBar(search) {
    return (
      <div>
        <div className="sidebar-column-small filter-column-read">
          <Popup
            trigger={
              search.unreadOnly ? (
                <FontAwesome
                  name="circle"
                  onClick={this.unreadChange.bind(this)}
                  className="active"
                />
              ) : (
                <FontAwesome
                  name="circle-o"
                  onClick={this.unreadChange.bind(this)}
                />
              )
            }
            content="Filter by new resumes"
          />
        </div>
        <div className="sidebar-column-extraLarge filter-column-dropdown">
          <p>Include:</p>
          <select
            onChange={this.filterDropDown}
            value={search.filterBy}
            className="filter-bar-select mui-select"
          >
            <option className="option" value="" label="All" />
            <option className="option" value="created" label="Created" />
            <option
              className="option"
              value="suggestions"
              label="My Suggestions"
            />
            <option className="option" value="shared" label="Shared" />
            <option
              className="option"
              value="ideas"
              label="Part of ideas I created"
            />
            <option className="option" value="engaged" label="Engaged" />
          </select>
        </div>
        <div className="sidebar-column-medium filter-column-favorite">
          <Popup
            trigger={
              search.pinnedOnly ? (
                <FontAwesome
                  name="star"
                  onClick={this.pinnedChange.bind(this)}
                  className="active"
                />
              ) : (
                <FontAwesome
                  name="star-o"
                  onClick={this.pinnedChange.bind(this)}
                />
              )
            }
            content="Filter by pinned resumes"
          />
        </div>
      </div>
    );
  }

  render() {
    const containerHeight = this.context.objectHeights.sidebarCalculatedHeight;
    let search = this.state.search;
    let constants = this.props.constants;
    let resumeCount = this.props.pagination.rowCount;
    let content;
    if (this.state.filterActive) {
      content = (
        <CSSTransition
          key="filter"
          classNames="searchFormTransition"
          timeout={700}
        >
          <div id="form" key="search">
            <Scrollbars style={{ height: containerHeight }}>
              <div className="search-form">
                <div className="form-section mui-row clearfix">
                  <legend>Gender</legend>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        className="mui-radio form-input-inline"
                        name="gender"
                        value={1}
                        checked={search.gender === 1 || search.gender === 0}
                        onChange={this.genderChanged}
                      />
                      Male
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        className="mui-radio form-input-inline"
                        name="gender"
                        value={2}
                        checked={search.gender === 2 || search.gender === 0}
                        onChange={this.genderChanged}
                      />
                      Female
                    </label>
                  </div>
                </div>
                <div className="form-section mui-row clearfix">
                  <legend>Relationship Status</legend>
                  {constants.maritalStatus
                    ? constants.maritalStatus.map((t) => {
                        return (
                          <label key={t.id}>
                            <input
                              type="checkbox"
                              className="mui-radio form-input-inline"
                              name="maritalStatus"
                              value={t.id}
                              checked={search.maritalStatus.includes(t.id)}
                              onChange={this.checkBoxChanged}
                            />
                            {t.text}
                          </label>
                        );
                      })
                    : null}
                  {search.maritalStatus.includes(2) ||
                  search.maritalStatus.includes(3) ? (
                    <div className="filter-children">
                      <p className="children-label">Number of Children</p>
                      <select
                        className="form-input-inline form-input-inline-select"
                        name="maxChildren"
                        value={search.maxChildren}
                        onChange={this.selectChanged}
                      >
                        <option value=""> </option>
                        <option value="0">None</option>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((x, i) => (
                          <option key={`maxChildren${x}`} value={x}>
                            {x} or less
                          </option>
                        ))}
                        <option value={10}>Any</option>
                      </select>
                    </div>
                  ) : null}
                </div>
                <div className="form-section mui-row">
                  <legend>Age Range</legend>
                  <input
                    className="search-input-inline"
                    min={16}
                    max={80}
                    type="number"
                    name="minAge"
                    onChange={this.inputChanged}
                    value={search.minAge}
                  />
                  <p className="form-input-inline search-input-inline-label">
                    to
                  </p>
                  <input
                    className="search-input-inline"
                    min={16}
                    max={80}
                    type="number"
                    name="maxAge"
                    onChange={this.inputChanged}
                    value={search.maxAge}
                  />
                </div>
                <div className="form-section mui-row">
                  <legend>Height Range</legend>
                  <select
                    name="minHeight"
                    onChange={this.selectChanged}
                    value={search.minHeight}
                    className="form-input-inline-select search-input-inline"
                  >
                    <option value=""> </option>
                    {_.range(56, search.maxHeight || 85).map((x, i) => (
                      <option key={`minH${i}`} value={x}>
                        {heightToString(x)}
                      </option>
                    ))}
                  </select>
                  <p className="form-input-inline search-input-inline-label">
                    to
                  </p>
                  <select
                    name="maxHeight"
                    onChange={this.selectChanged}
                    value={search.maxHeight}
                    className="form-input-inline-select search-input-inline"
                  >
                    <option value=""> </option>
                    {_.range(
                      search.minHeight ? search.minHeight + 1 : 56,
                      85,
                    ).map((x, i) => (
                      <option key={`maxH${i}`} value={x}>
                        {heightToString(x)}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-section mui-row filter-types clearfix">
                  <div className="mui-col-md-12">
                    <legend>Types</legend>
                    {constants.types
                      ? constants.types.map((t) => {
                          return (
                            <label key={`types${t.id}`}>
                              <input
                                type="checkbox"
                                className="mui-radio form-input-inline"
                                name="types"
                                value={t.id}
                                checked={this.isTypeSelected(t.id)}
                                onChange={this.typeChanged}
                              />
                              {t.text}
                            </label>
                          );
                        })
                      : null}
                  </div>
                </div>
                <div className="form-section mui-row filter-languages clearfix">
                  <div className="mui-col-md-12">
                    <legend>Languages</legend>
                    {constants.languages
                      ? constants.languages.map((l) => {
                          return (
                            <label key={`languages${l.id}`}>
                              <input
                                type="checkbox"
                                className="mui-radio form-input-inline"
                                name="languages"
                                value={l.id}
                                checked={search.languages.includes(l.id)}
                                onChange={this.checkBoxChanged}
                              />
                              {l.text}
                            </label>
                          );
                        })
                      : null}
                  </div>
                </div>
                <div className="form-section mui-row filter-rb clearfix">
                  <div className="mui-col-md-12">
                    <legend>Religious Background</legend>
                    {constants.religious
                      ? constants.religious.map((r) => {
                          return (
                            <label key={`religious${r.id}`}>
                              <input
                                type="checkbox"
                                name="religious"
                                value={r.id}
                                checked={search.religious.includes(r.id)}
                                onChange={this.checkBoxChanged}
                                className="mui-radio form-input-inline"
                              />
                              {r.text}
                            </label>
                          );
                        })
                      : null}
                  </div>
                </div>
                <div className="form-section mui-row filter-ethnicity clearfix">
                  <div className="mui-col-md-12">
                    <legend>Ethnicity</legend>
                    {constants.ethnicity
                      ? constants.ethnicity.map((e) => {
                          return (
                            <label key={`ethnicity${e.id}`}>
                              <input
                                type="checkbox"
                                name="ethnicity"
                                value={e.id}
                                checked={search.ethnicity.includes(e.id)}
                                onChange={this.checkBoxChanged}
                                className="mui-radio form-input-inline"
                              />
                              {e.text}
                            </label>
                          );
                        })
                      : null}
                  </div>
                </div>
                <div className="form-section mui-row">
                  <div className="mui-col-md-12">
                    <legend>Search by Location</legend>
                    <input
                      placeholder="Search by location"
                      className="search-dropdown-input"
                      type="text"
                      name="location"
                      value={search.location}
                      onChange={this.inputChanged}
                    />
                  </div>
                </div>
                <div className="form-section mui-row">
                  <div className="mui-col-md-12">
                    <legend>Search in Notes</legend>
                    <input
                      placeholder="Search in your notes"
                      className="search-dropdown-input"
                      type="text"
                      name="note"
                      value={search.note}
                      onChange={this.inputChanged}
                    />
                  </div>
                </div>
                <div className="form-section mui-row">
                  <div className="mui-col-md-12">
                    <legend>Search by Tags</legend>
                    <MultiSelect
                      placeholder="Search in your tags"
                      className="react-tags search-dropdown-input"
                      data={this.props.userTags}
                      value={search.userTags}
                      onChange={this.userTagsChanged.bind(this)}
                      minLength={0}
                      textField="tagText"
                      valueField="id"
                      filter="contains"
                    />
                  </div>
                </div>
                {this.props.constants.groupsImPartOf ? (
                  <div className="form-section mui-row">
                    <div className="mui-col-md-12">
                      <legend>Filter by groups</legend>
                      <MultiSelect
                        placeholder="choose groups to include"
                        className="react-tags search-dropdown-input"
                        data={this.props.constants.groupsImPartOf}
                        value={search.groups}
                        onChange={this.groupsChanged.bind(this)}
                        minLength={0}
                        textField="name"
                        valueField="id"
                        filter="contains"
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </Scrollbars>
            <div className="search-dropdown-box-footer">
              <button
                onClick={this.clearSearch}
                className="btn-flat btn-reset"
                type="button"
              >
                Clear
              </button>
              <button
                onClick={this.searchAndClose}
                className="btn-flat btn-success"
                type="button"
              >
                Search
              </button>
            </div>
          </div>
        </CSSTransition>
      );
    } else {
      content = (
        <CSSTransition
          key="list"
          classNames="searchFormTransition"
          timeout={700}
        >
          <div key="list">
            {/* <div id="filter-bar" className="filter-bar">
              {this.renderFilterBar(search)}
            </div> */}
            {this.state.searchActive ? (
              <div className="sidebar-list-results" id="sidebar-list-results">
                <p>
                  <span className="sidebar-list-results-bold">
                    {resumeCount}{" "}
                  </span>
                  Result{resumeCount === 1 ? "" : "s"}
                </p>
                <p onClick={this.clearSearch} className="" type="button">
                  <span>Clear filters</span>
                </p>
              </div>
            ) : (
              ""
            )}
            <div className="sidebar-list" id="resumeList">
              <ul>
                <Infinite
                  ref={(infinite) => {
                    this.infinite = infinite;
                  }}
                  selectedIndex={this.props.currentResumeIndex}
                  containerHeight={containerHeight}
                  hasMore={resumeCount > this.props.resumes.length}
                  className="infinite"
                  loadingMore={!!this.props.isLoading}
                  itemList={this.props.resumes}
                  renderRow={this.resumeRow.bind(this)}
                  noItemsText="No resumes."
                  loadMore={this.props.loadMoreResumes}
                />
              </ul>
            </div>
            {/* <div className="sidebar-list-count" id="sidebar-list-count">
              <p>
                {resumeCount} Resume{resumeCount > 1 ? "s" : ""}
              </p>
            </div> */}
          </div>
        </CSSTransition>
      );
    }
    return (
      <div
        id="sidebar-left"
        className={`sidebar-left-filter header-padding ${
          this.props.displayMobile ? "" : "mobile-hide"
        }`}
        key="sidebar-filter"
      >
        {/* <div className="search-filter" id="search-filter">
          <div className="search-bar">
            <div className="search search-with-filter">
              <input
                className="input"
                type="text"
                placeholder="Search Resumes"
                name="searchText"
                onKeyPress={this.handleKeyPress}
                onChange={this.inputChanged}
                value={search.searchText}
                autoComplete="off"
              />
              <FontAwesome
                name="sliders"
                className={`btn-search-filter ${
                  this.state.filterActive || this.state.searchActive
                    ? "btn-search-filter-active"
                    : ""
                }`}
                onClick={this.filterClick.bind(this)}
              />
            </div>
          </div>
        </div> */}
        <TransitionGroup className="search-form-container">
          {content}
        </TransitionGroup>
      </div>
    );
  }
}

ResumeLeftPanel.propTypes = {
  search: PropTypes.object.isRequired,
  emptySearch: PropTypes.object.isRequired,
  displayMobile: PropTypes.bool,
  selectedResume: PropTypes.number,
  resumes: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  isLoading: PropTypes.number,
  userTags: PropTypes.array.isRequired,
  constants: PropTypes.object.isRequired,
  loadMoreResumes: PropTypes.func.isRequired,
  loadResumes: PropTypes.func.isRequired,
  selectResume: PropTypes.func.isRequired,
  currentResumeIndex: PropTypes.number,
};

export default ResumeLeftPanel;

import * as types from "../actionTypes/userActionTypes";

const initialState = {};

export default function uiReducer(state = initialState, action) {
  switch (action.type) {
    case types.SAVE_SHUL_ID:
      return {
        ...state,
        shul_group_id: action.shul_group_id,
      };
    default:
      return state;
  }
}

import React from "react";
import ShareToUser from "./shareToUserModal";
import ShareToGroup from "./shareToGroupModal";

class ShareResumeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shareTo: props.shareTo, //user or group
    };
  }

  render() {
    let modalContent;
    if (this.state.shareTo === "user") {
      modalContent = (
        <ShareToUser
          removeModal={this.props.removeModal}
          currentResume={this.props.currentResume}
          shareToGroup={() => this.setState({ shareTo: "group" })}
        />
      );
    } else {
      modalContent = (
        <ShareToGroup
          removeModal={this.props.removeModal}
          currentResume={this.props.currentResume}
          shareToUser={() => this.setState({ shareTo: "user" })}
        />
      );
    }
    return <div>{modalContent}</div>;
  }
}

export default ShareResumeModal;

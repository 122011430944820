import * as types from "../actionTypes/resumeActionTypes";
import { mergeCurrentItemsWithNextPage } from "../components/common/utils";

export default function resumeReducer(
  state = { resumes: [], pagination: { page: 1 }, search: {} },
  action,
) {
  switch (action.type) {
    case types.LOAD_RESUMES_SUCCESS:
      let pageInfo = action.resumes.pagination;
      let newResumes = mergeCurrentItemsWithNextPage(
        state.resumes,
        action.resumes.results,
        pageInfo.page,
        pageInfo.pageSize,
      );
      return {
        ...state,
        pagination: { ...state.pagination, ...action.resumes.pagination },
        isLoading: state.isLoading ? state.isLoading - 1 : 0,
        resumes: newResumes,
      };
    case types.LOAD_RESUMES_START:
      return {
        ...state,
        pagination: state.pagination,
        isLoading:
          state.isLoading || state.isLoading === 0 ? state.isLoading + 1 : 1,
        resumes: action.search.pageNumber === 1 ? [] : state.resumes,
        search: action.search,
      };
    case types.CANCEL_RESUME_LOADING:
      return {
        ...state,
        isLoading: 0,
      };
    case types.LOAD_SINGLE_RESUME_SUCCESS:
      return {
        ...state,
        resumes: state.resumes.map((resume) => {
          if (resume && resume.id === action.resume.id) {
            resume.viewed = true;
          }
          return resume;
        }),
      };
    case types.PIN_RESUME:
      return {
        ...state,
        resumes: state.resumes.map((resume) => {
          if (resume && resume.id === action.resumeId) {
            resume.pinned = true;
          }
          return resume;
        }),
      };
    case types.UNPIN_RESUME:
      return {
        ...state,
        resumes: state.resumes.map((resume) => {
          if (resume && resume.id === action.resumeId) {
            resume.pinned = false;
          }
          return resume;
        }),
      };
    default:
      return state;
  }
}

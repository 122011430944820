import React from "react";
import Autosuggest from "react-autosuggest";
import isMobile from "ismobilejs";
import { apiAuthRequest } from "../../actions/helpers";

function getSuggestionValue(suggestion) {
  return `${suggestion.firstName} ${suggestion.lastName}`;
}
function renderSuggestion(user) {
  return (
    <div key={user.id} className="autosuggest-list-item">
      <div className="autosuggest-list-item-info autosuggest-column-large">
        <h5 className="autosuggest-list-item-title">
          {user.firstName} {user.lastName}
        </h5>
        <p className="autosuggest-list-item-info-details">
          <span>{user.email}</span>
        </p>
      </div>
    </div>
  );
}

export default class userAutosuggest extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value || "",
      suggestions: [],
      isLoading: false,
    };
    this.name = this.props.name;
    this.lastRequestId = null;
  }

  loadSuggestions(value) {
    // Cancel the previous request
    if (this.lastRequestId !== null) {
      clearTimeout(this.lastRequestId);
    }

    this.setState({
      isLoading: true,
    });

    let inside = this;
    apiAuthRequest("/api/users/autocomplete", { query: value }).then(
      (result) => {
        if (result.success) {
          inside.setState({
            isLoading: false,
            suggestions: result.data,
          });
        }
      },
    );
  }

  onChange = (event, { newValue }) => {
    this.props.onChange(newValue);
    if (!newValue) {
      this.props.itemSelected(this.props.fieldName, null);
    }
    this.setState({ value: newValue });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.loadSuggestions(value);
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  onSuggestionSelected = (event, { suggestion }) => {
    if (event && event.key === "Enter") {
      // might be in a form, so prevent this enter keystroke from submitting the form.
      event.preventDefault();
    }
    let fieldName = this.props.fieldName;
    this.props.itemSelected(fieldName, suggestion.id);
  };

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: this.props.placeholder,
      value,
      onBlur: this.props.onBlur,
      onChange: this.onChange,
      name: this.props.name,
      onFocus: () => this.loadSuggestions(value),
    };

    return (
      <div className="container autosuggest-list">
        <Autosuggest
          suggestions={suggestions}
          focusInputOnSuggestionClick={!isMobile.any}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          onSuggestionSelected={this.onSuggestionSelected.bind(this)}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
        />
      </div>
    );
  }
}

import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Portal from "./Portal";

class Popup extends Component {
  state = {
    positioning: "top left",
  };

  computePopupStyle(positions) {
    const style = { position: "absolute" };
    const { offset } = this.props;
    const { pageYOffset, pageXOffset } = window;
    const { clientWidth, clientHeight } = document.documentElement;

    if (_.includes(positions, "right")) {
      style.right = Math.round(clientWidth - (this.coords.right + pageXOffset));
      style.left = "auto";
    } else if (_.includes(positions, "left")) {
      style.left = Math.round(this.coords.left + pageXOffset);
      style.right = "auto";
    } else {
      // if not left nor right, we are horizontally centering the element
      const xOffset = (this.coords.width - this.popupCoords.width) / 2;
      style.left = Math.round(this.coords.left + xOffset + pageXOffset);
      style.right = "auto";
    }

    if (_.includes(positions, "top")) {
      style.bottom = Math.round(clientHeight - (this.coords.top + pageYOffset));
      style.top = "auto";
    } else if (_.includes(positions, "bottom")) {
      style.top = Math.round(this.coords.bottom + pageYOffset);
      style.bottom = "auto";
    } else {
      // if not top nor bottom, we are vertically centering the element
      const yOffset = (this.coords.height + this.popupCoords.height) / 2;
      style.top = Math.round(this.coords.bottom + pageYOffset - yOffset);
      style.bottom = "auto";

      const xOffset = this.popupCoords.width + 8;
      if (_.includes(positions, "right")) {
        style.right -= xOffset;
      } else {
        style.left -= xOffset;
      }
    }

    if (offset) {
      if (_.isNumber(style.right)) {
        style.right -= offset;
      } else {
        style.left -= offset;
      }
    }

    return style;
  }

  // check if the style would display
  // the popup outside of the view port
  isStyleInViewport(style) {
    const { pageYOffset, pageXOffset } = window;
    const { clientWidth, clientHeight } = document.documentElement;

    const element = {
      top: style.top,
      left: style.left,
      width: this.popupCoords.width,
      height: this.popupCoords.height,
    };
    if (_.isNumber(style.right)) {
      element.left = clientWidth - style.right - element.width;
    }
    if (_.isNumber(style.bottom)) {
      element.top = clientHeight - style.bottom - element.height;
    }

    // hidden on top
    if (element.top < pageYOffset) return false;
    // hidden on the bottom
    if (element.top + element.height > pageYOffset + clientHeight) return false;
    // hidden the left
    if (element.left < pageXOffset) return false;
    // hidden on the right
    if (element.left + element.width > pageXOffset + clientWidth) return false;

    return true;
  }

  setPopupStyle() {
    if (!this.coords || !this.popupCoords) return;
    let positioning = "top left";
    let style = this.computePopupStyle(positioning);

    // Lets detect if the popup is out of the viewport and adjust
    // the position accordingly
    const positions = _.without(
      [
        "top left",
        "top right",
        "bottom right",
        "bottom left",
        "right center",
        "left center",
        "top center",
        "bottom center",
      ],
      positioning,
    );
    for (
      let i = 0;
      !this.isStyleInViewport(style) && i < positions.length;
      i++
    ) {
      style = this.computePopupStyle(positions[i]);
      positioning = positions[i];
    }

    // Append 'px' to every numerical values in the style
    style = _.mapValues(style, (value) =>
      _.isNumber(value) ? value + "px" : value,
    );
    this.setState({ style, positioning });
  }

  hideOnScroll = (e) => {
    if (this.refs[this.popupMounted]) {
      this.setState({ closed: true });
    }
    window.removeEventListener("scroll", this.hideOnScroll);
    setTimeout(() => {
      if (this.refs[this.popupMounted]) this.setState({ closed: false });
    }, 50);
  };

  handleOpen = (e) => {
    this.coords = e.currentTarget.getBoundingClientRect();
  };

  handlePortalMount = (e) => {
    window.addEventListener("scroll", this.hideOnScroll);
  };

  popupMounted = (ref) => {
    this.popupCoords = ref ? ref.getBoundingClientRect() : null;
    this.setPopupStyle();
  };

  render() {
    const { className, content, trigger } = this.props;

    const { positioning, closed } = this.state;
    const style = _.assign({}, this.state.style, this.props.style);
    const classes = `ui popup transition visible ${positioning} ${className}`;

    if (closed) return trigger;

    const popupJSX = (
      <div className={classes} style={style} ref={this.popupMounted}>
        <div className="content">{content}</div>
      </div>
    );

    return (
      <Portal
        trigger={trigger}
        onMount={this.handlePortalMount}
        onOpen={this.handleOpen}
      >
        {popupJSX}
      </Portal>
    );
  }
}

Popup.propTypes = {
  /** Simple text content for the popover. */
  content: PropTypes.string,

  /** Element to be rendered in-place where the popup is defined. */
  trigger: PropTypes.node,

  className: PropTypes.string,
};

export default Popup;

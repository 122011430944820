import * as types from "../actionTypes/constantsTypes";

export default function constantsReducer(state = {}, action) {
  switch (action.type) {
    case types.LOAD_CONSTS_SUCCESS:
      return {
        ...state,
        ...action.consts,
      };
    case types.LOAD_GROUPS_LIST:
      return {
        ...state,
        groupsImPartOf: action.groups,
      };
    default:
      return state;
  }
}
